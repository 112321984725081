import { errorOn, type ShipcloudApiError } from '@/api/shipcloud'
import * as additionalServicesJson from '@/api/shipcloud/additionalServices.json'
import { Address, getDefaultAddressObject } from '@/api/shipcloud/address'
import type {
  AdditionalService,
  InlineReturn,
  NewShipment,
  Shipment
} from '@/api/shipcloud/shipment'
import { getDefaultNewShipment, NewShipment as NewShipmentInstance } from '@/api/shipcloud/shipment'
import type { ShipmentFormRatesProps } from '@/components/ShipmentForm'
import { logError } from '@/helpers'
import { useAddressesStore } from '@/stores/addresses'
import { useCarriersStore } from '@/stores/carriers'
import { useShipmentsStore } from '@/stores/shipments'
import { storeToRefs } from 'pinia'
import { computed, readonly, ref, watch, type ComputedRef, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useShipment = () => {
  const addressessStore = useAddressesStore()
  const carriersStore = useCarriersStore()
  const { carrierSupportsReturns } = carriersStore
  const shipmentsStore = useShipmentsStore()
  const { t } = useI18n()
  const shipment: Ref<NewShipment | undefined> = ref()
  const error: ComputedRef<ShipcloudApiError | undefined> = computed(() => shipmentsStore.error)
  const saving = computed(() => shipmentsStore.saving)
  const loading: ComputedRef<boolean> = computed(
    () => addressessStore.loading || shipmentsStore.loading
  )
  const inlineReturn = ref<InlineReturn>({
    create: false,
    reference_number: '',
    defaultReturnsAddressPresent: false
  })
  const { inlineReturnId } = storeToRefs(shipmentsStore)
  const allAdditionalServices = new Map(Object.entries(additionalServicesJson))
  const parseAddress = (apiAddress: Address | undefined): Address => {
    try {
      const address = Address.parse(apiAddress)
      return address || getDefaultAddressObject()
    } catch (e: any) {
      logError(e)
      return getDefaultAddressObject()
    }
  }
  const shippingAddress = async (): Promise<Address> => {
    await addressessStore.fetchDefaultShippingAddress()
    return parseAddress(addressessStore.defaultShippingAddress)
  }
  const defaultReturnsAddress = async (): Promise<Address> => {
    await addressessStore.fetchDefaultReturnsAddress()
    return parseAddress(addressessStore.defaultReturnsAddress)
  }

  const carrierFilterOptions = computed(() => carriersStore.carrierOptions)
  const returnCarrierFilterOptions = computed(() =>
    carriersStore.carrierOptions.filter((carrier) =>
      carrierSupportsReturns(carrier.value as string)
    )
  )
  const serviceFilterOptions = computed(() => {
    const services = shipment.value?.carrier
      ? carriersStore.serviceOptionsPerCarrier?.[shipment.value.carrier]
      : []
    return (services || []).filter((service) => service.value !== 'returns')
  })

  const returnsAddress = ref<Address>()
  watch(
    () => inlineReturn.value.create,
    async () => {
      await addressessStore.fetchDefaultReturnsAddress()
      returnsAddress.value = addressessStore.defaultReturnsAddress
      inlineReturn.value.defaultReturnsAddressPresent = !!returnsAddress.value
      if (!returnsAddress.value) returnsAddress.value = shipment.value?.from
    }
  )

  const showCustomsDeclaration = computed<boolean>(
    () =>
      shipment.value?.customs_declaration !== undefined ||
      (shipment.value?.from?.country &&
        shipment.value?.to?.country &&
        shipment.value.from.country !== shipment.value.to.country) ||
      false
  )

  const additionalServices = computed(() => {
    const additionalServices: AdditionalService[] = []

    if (shipment.value?.carrier) {
      carriersStore.carriers[shipment.value.carrier].additional_services.forEach((name) => {
        if (allAdditionalServices.has(name)) {
          const definedProperties = allAdditionalServices.get(name)
          const properties = definedProperties?.length
            ? new Map(definedProperties.map((p) => [p, '']))
            : null
          additionalServices.push({ name, properties })
        }
      })
    }

    return additionalServices
  })

  const additionalServiceErrors = computed(() => {
    if (!additionalServices.value.length || !error.value) return []
    return additionalServices.value
      .filter((service) =>
        errorOn(error.value, {
          property: t(`AdditionalService.names.${service.name}`, {}, { locale: 'en' })
        })
      )
      .map((service) => service.name)
  })

  const fromExistingShipment = (existing: Shipment): NewShipment => {
    const shipmentData = {
      ...existing,
      package: existing.packages[0],
      create_shipping_label: true
    }
    return NewShipmentInstance.parse(shipmentData)
  }

  const saveShipment = async () => {
    if (!shipment.value) return

    const createShipmentCall = shipmentsStore.createShipment(shipment.value)

    if (inlineReturn.value.create) {
      const outboundShipmentId = await createShipmentCall
      if (outboundShipmentId) {
        const returnsShipment: NewShipment = {
          ...shipment.value,
          additional_services: [],
          service: 'returns',
          from: shipment.value.to!,
          to: returnsAddress.value,
          reference_number: inlineReturn.value.reference_number
        }
        const createReturnShipment = await shipmentsStore.createShipment(returnsShipment)
        inlineReturnId.value = createReturnShipment
      }
      return outboundShipmentId
    } else return createShipmentCall
  }

  const newShipment = async (createReturn: boolean, existingShipmentId?: string) => {
    await carriersStore.fetchCarriers()
    let existingShipment: Shipment | undefined
    if (existingShipmentId) {
      await shipmentsStore.fetchShipment(existingShipmentId)
      existingShipment = shipmentsStore.shipmentById(existingShipmentId)
      shipment.value = fromExistingShipment(existingShipment)
    } else {
      shipment.value = getDefaultNewShipment()
      if (!createReturn) shipment.value.from = await shippingAddress()
    }

    if (createReturn) {
      // don't mix up addresses for a copied return
      if (existingShipment && existingShipment.service !== 'returns') {
        shipment.value.from = shipment.value.to!
      }
      shipment.value.to = await defaultReturnsAddress()
      shipment.value.service = 'returns'
      shipment.value.additional_services = undefined
      shipment.value.customs_declaration = undefined
      if (!carrierSupportsReturns(shipment.value.carrier)) shipment.value.carrier = ''
    }
  }

  watch(
    () => shipment.value?.carrier,
    (newVal, oldVal) => {
      // we need to clear additional services on carrier change, so we don't send incompatible add. services
      if (oldVal) {
        if (oldVal !== newVal) shipment.value!.additional_services = []
      }
    }
  )

  const shipmentQuote = ref<Awaited<ReturnType<typeof shipmentsStore.getShipmentQuote>>>()

  // Upon changing anything about the shipment, reset the quote
  watch([() => shipment.value], () => (shipmentQuote.value = undefined), { deep: true })

  const shipmentQuoteLoading = ref(false)
  const shipmentQuoteFetch = async () => {
    if (!shipment.value || shipmentQuote.value) return

    shipmentQuoteLoading.value = true
    const quote = await shipmentsStore.getShipmentQuote({
      ...shipment.value
    })

    shipmentQuote.value = quote
    shipmentQuoteLoading.value = false
  }

  const shipmentQuoteInfo = computed((): ShipmentFormRatesProps | undefined => {
    if (!shipmentQuote.value || !shipment.value?.carrier) return undefined

    return {
      carrierLabel: carriersStore.carriers?.[shipment.value.carrier].display_name,
      carrierName: shipment.value.carrier,
      quote: shipmentQuote.value,
      serviceLabel: serviceFilterOptions.value?.find(
        (option) => option.value === shipment.value!.service
      )?.label
    }
  })

  return {
    additionalServices,
    additionalServiceErrors,
    carrierFilterOptions,
    error,
    inlineReturn,
    loading,
    newShipment,
    saving,
    saveShipment,
    returnCarrierFilterOptions,
    carrierSupportsReturns,
    serviceFilterOptions,
    shipment,
    shipmentQuoteInfo,
    shipmentQuoteFetch,
    shipmentQuoteLoading: readonly(shipmentQuoteLoading),
    showCustomsDeclaration
  }
}
