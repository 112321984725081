import { z } from 'zod'
import { isMasterCarton, Shipment } from './shipment'

export const DeliveryItem = z.object({
  id: z.string(),
  length: z.number(),
  width: z.number(),
  height: z.number(),
  weight: z.number(),
  packagingType: z.enum(['CRT', 'PAL', 'ENV', 'TUB', 'BAG']).optional(),
  shipments: Shipment.array()
})
export type DeliveryItem = z.infer<typeof DeliveryItem>

export const getMasterCartonShipmentFromDeliveryItem = (deliveryItem: DeliveryItem) =>
  deliveryItem.shipments.filter((shipment) => isMasterCarton(shipment))?.[0]

export const getLastShipmentFromDeliveryItem = (deliveryItem: DeliveryItem) =>
  deliveryItem.shipments.filter((shipment) => shipment.transportStepKind === 'LastMile')?.[0] ||
  deliveryItem.shipments.filter((shipment) => shipment.transportStepKind === 'DirectShipping')?.[0]
