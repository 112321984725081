import {
  CreateDeliveryRequest,
  defaultFormDelivery,
  REFERENCE_NUMBER_TYPES,
  CreateDeliveryRequest as ZodCreateDeliveryRequest,
  FormDelivery as ZodFormDelivery,
  type AddressRole,
  type Address as FormAddress,
  type FormDelivery
} from '@/api/apiLayer'
import { GetDelivery, type Address, type Delivery } from '@/api/parcelCore'
import type { SuiteFilterProps } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

export const initDelivery = async (
  parcelCoreDeliveryId?: string,
  clientCode?: string
): Promise<FormDelivery> => {
  if (parcelCoreDeliveryId?.length) {
    const parcelCoreDelivery = await GetDelivery(parcelCoreDeliveryId)
    if (parcelCoreDelivery?.id == parcelCoreDeliveryId)
      return fromParcelCoreDelivery(parcelCoreDelivery, clientCode)
  }
  return defaultFormDelivery(clientCode)
}
export const formDeliveryToRequest = (
  delivery: FormDelivery,
  outputFormat: string
): CreateDeliveryRequest => {
  const request = {
    outputFormat,
    deliveries: [
      { ...Object.assign(delivery), addresses: [delivery.pickupAddress, delivery.deliveryAddress] }
    ]
  }
  return ZodCreateDeliveryRequest.parse(request)
}
const fromParcelCoreAddress = (role: AddressRole, parcelCoreAddress?: Address): FormAddress => ({
  ...Object.assign(parcelCoreAddress || {}),
  role,
  company: parcelCoreAddress?.addressLine1,
  firstName: parcelCoreAddress?.addressLine2,
  zipcode: parcelCoreAddress?.postalCode,
  country: parcelCoreAddress?.countryCode,
  addressLine2: parcelCoreAddress?.contact,
  email: parcelCoreAddress?.eMail
})
export const fromParcelCoreDelivery = (
  parcelCoreDelivery: Delivery,
  clientCode?: string
): FormDelivery =>
  ZodFormDelivery.parse({
    ...Object.assign(parcelCoreDelivery),
    clientCode,
    deliveryAddress: fromParcelCoreAddress('delivery', parcelCoreDelivery.to),
    pickupAddress: fromParcelCoreAddress('pickup', parcelCoreDelivery.from),
    deliveryType: { productCode: '' },
    items: [
      {
        dimensions: [
          parcelCoreDelivery.deliveryItems[0].height.toString(),
          parcelCoreDelivery.deliveryItems[0].length.toString(),
          parcelCoreDelivery.deliveryItems[0].width.toString()
        ],
        weight: parcelCoreDelivery.deliveryItems[0].weight.toString(),
        packagingType: 'carton'
      }
    ]
  })

export const buildReferenceNumberProps = (i18n: ReturnType<typeof useI18n>): SuiteFilterProps => {
  const { t } = i18n()
  const filters: SuiteFilterProps['filters'] = {}
  Object.keys(REFERENCE_NUMBER_TYPES).forEach((referenceNumberType) => {
    filters[referenceNumberType] = {
      label: t(`ReferenceNumberTypes.${referenceNumberType}`),
      placeholder: t(`ReferenceNumberTypes.${referenceNumberType}`),
      type: 'text'
    }
  })
  return {
    filters,
    strAddFilterSelectionPlaceholder: t('DeliveryForm.referenceNumberAdd'),
    strButtonAddFilter: t('DeliveryForm.referenceNumberAdd'),
    strButtonAddFilterCancelAdd: t('App.cancel'),
    strButtonAddFilterCancelEdit: t('App.cancel'),
    strFilterToAddLabelFilter: t('DeliveryForm.referenceNumberType'),
    strFilterToAddLabelValue: t('Office.ShipmentsDashboard.filterValueLabel'),
    strNoFiltersSet: t('DeliveryForm.noReferenceNumbers'),
    strSetFilterButton: t('App.add')
  }
}
