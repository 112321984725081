import type { Shipment } from '@/api/shipcloud/shipment'
import { shortId } from '@/helpers'
import type { useI18n } from 'vue-i18n'

export const buildSummary = (shipment: Shipment, i18n: ReturnType<typeof useI18n>) => {
  const { d, t } = i18n()
  const items = [
    {
      label: t('Shipment.id'),
      value: shortId(shipment.id)
    },
    { label: t('Shipment.created_at'), value: d(shipment.created_at) },
    {
      label: t('Shipment.carrier'),
      value: t(`Shipment.carriers.${shipment.carrier}`, shipment.carrier)
    },
    {
      label: t('Shipment.service'),
      value: t(`Shipment.services.${shipment.service}`, shipment.service)
    }
  ]
  return { items }
}
