<script setup lang="ts">
import type { Delivery } from '@/api/parcelCore'
import { SuiteButton, SuiteIcon } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

type DeliveryDetailsActionsProps = { delivery: Delivery }
const props = defineProps<DeliveryDetailsActionsProps>()

const { t } = useI18n()
const router = useRouter()

const toDeliveryForm = () =>
  router.push({ name: 'create-delivery-from-existing', params: { id: props.delivery.id } })
</script>

<template>
  <SuiteButton @click="toDeliveryForm" :title="t('DeliveryForm.nav')">
    <SuiteIcon icon="Plus" class="size-5" />
  </SuiteButton>
</template>
