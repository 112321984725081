import type { Shipment } from '@/api/shipcloud/shipment'
import type { SuiteTableNestedProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { shipmentsToSuiteTableNestedBody } from './body'
import { shipmentsToSuiteTableNestedHead } from './head'

export const shipmentsToSuiteTableNested = (
  shipments: Shipment[],
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>
) => {
  if (!shipments.length) return undefined

  return {
    body: shipmentsToSuiteTableNestedBody(shipments, i18n, router),
    cols: { 7: { class: 'w-4' } },
    head: shipmentsToSuiteTableNestedHead(i18n),
    headStyle: 'blue'
  } as SuiteTableNestedProps
}
