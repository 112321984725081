import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, { type BugsnagPluginVueResult } from '@bugsnag/plugin-vue'
import VueIntercom from '@homebaseai/vue3-intercom'
import '@shipcloud/suite-components/dist/style.css'
import 'flag-icons/css/flag-icons.min.css'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'

import '@/assets/main.css'

import App from '@/App.vue'
import i18n from '@/i18n'
import router from '@/router'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const bugsnagApiKey = import.meta.env.PROD ? import.meta.env.VITE_BUGSNAG_API_KEY : undefined
if (bugsnagApiKey) {
  const bugsnagConfig = {
    apiKey: bugsnagApiKey,
    releaseStage: import.meta.env.MODE
  }
  Bugsnag.start({ ...bugsnagConfig, plugins: [new BugsnagPluginVue()] })
  BugsnagPerformance.start(bugsnagConfig)
}

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(VueIntercom)
if (bugsnagApiKey) {
  app.use(Bugsnag.getPlugin('vue') as BugsnagPluginVueResult)
}

app.mount('#app')
