import { DeliveryFilter } from '@/api/parcelCore'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDeliveriesFilterStore = defineStore(
  'deliveriesFilter',
  () => {
    const filter = ref<DeliveryFilter>({})

    if (!filter.value.type) filter.value.type = 'deliveries'

    const updateByQueryParams = (queryParams: unknown) => {
      const parsed = DeliveryFilter.safeParse(queryParams)
      if (parsed.success && Object.keys(parsed.data).length) {
        const parsedFilter = parsed.data
        if (!parsedFilter.type) parsedFilter.type = 'deliveries'
        filter.value = parsedFilter
      }
    }

    return {
      updateByQueryParams,
      filter
    }
  },
  { persist: true }
)
