import type { CustomsDeclaration, CustomsDeclarationItem } from '@/api/shipcloud/shipment'
import { weight } from '@/helpers'
import type { SuiteTableNestedRow } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

const customsDeclarationCurrencyValue = (
  value: string,
  currency: string,
  i18n: ReturnType<typeof useI18n>
) => {
  const { n } = i18n()
  return currency == 'EUR' ? n(Number(value), 'currency') : `${value} ${currency}`
}

const itemTableRow = (
  item: CustomsDeclarationItem,
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
): SuiteTableNestedRow['cells'] => {
  const { n } = i18n()
  return [
    item.quantity,
    item.description,
    item.origin_country,
    customsDeclarationCurrencyValue(item.value_amount, customsDeclaration.currency, i18n),
    weight(n(item.net_weight)),
    item.gross_weight ? weight(n(item.gross_weight)) : '',
    item.hs_tariff_number
  ]
}

export const buildCustomsTable = (
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
) => {
  const { t } = i18n()

  const head = [
    {
      cells: [
        t('CustomsDeclarationItem.quantity'),
        t('CustomsDeclarationItem.description'),
        t('CustomsDeclarationItem.origin_country'),
        t('CustomsDeclarationItem.value_amount'),
        t('CustomsDeclarationItem.net_weight'),
        t('CustomsDeclarationItem.gross_weight'),
        t('CustomsDeclarationItem.hs_tariff_number')
      ]
    }
  ]

  const body = customsDeclaration.items.map((item) => {
    return { cells: itemTableRow(item, customsDeclaration, i18n) }
  })

  return { head, body, noAlternatingRows: false }
}
