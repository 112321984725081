<script setup lang="ts">
import type {
  SuiteHeaderDropdownMenuItemSelected,
  SuiteHeaderNavigationItem
} from '@shipcloud/suite-components'
import {
  SuiteAppLayout,
  SuiteHeaderDropdown,
  SuiteHeaderNavigation,
  SuiteIcon
} from '@shipcloud/suite-components'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouterView, useRouter } from 'vue-router'

import { localizeURL } from '@/helpers'
import { languages, setLocale } from '@/i18n'
import { useCredentialsStore } from '@/stores/credentials'
import { computed, type ComputedRef } from 'vue'
import { useIntercomWidget } from '../composables/useIntercomWidget'

const { locale, t, tm } = useI18n({ useScope: 'global' })

const router = useRouter()
const { hasRoute } = router
const credentialsStore = useCredentialsStore()
const app_host = localizeURL(locale.value)

const returnAuthUrl = import.meta.env.VITE_RETURN_AUTH_URL
const { returnbirdAccountId, routingRulesEnabled, user } = storeToRefs(credentialsStore)
const headerBarProps = { logoHref: router.resolve({ name: 'suite-entry' }).href }
const footerBarProps = {
  languages,
  initialLanguage: locale.value,
  linkDisplayNames: tm('FooterBar.links')
}
const headerDropdownProps = computed(() => {
  if (!credentialsStore.userEmail) return undefined
  return {
    title: credentialsStore.userEmail,
    items: [
      { id: 'edit_password', label: t('App.HeaderDropdown.edit_password') },
      { id: 'logout', label: t('App.HeaderDropdown.logout') }
    ],
    darkMode: false,
    footer: false,
    onSelect: headerItemSelected
  }
})
const headerItemSelected = (event: SuiteHeaderDropdownMenuItemSelected) => {
  switch (event.item.id) {
    case 'logout':
      credentialsStore.clear()
      return (location.href = `${app_host}/sign_out`)
    case 'edit_password':
      return (location.href = `${app_host}/users/edit`)
  }
}
const buildNavigationItems: ComputedRef<SuiteHeaderNavigationItem[]> = computed(() => {
  let shipping: SuiteHeaderNavigationItem = {
    id: 'shipments-dashboard',
    title: t('Navigation.shipments'),
    children: [
      {
        id: 'create-shipment',
        title: t('Navigation.createShipment')
      },
      {
        id: 'pickup-requests-dashboard',
        title: t('Navigation.pickupRequests')
      }
    ]
  }
  let settings: SuiteHeaderNavigationItem = {
    id: localizeURL(locale.value),
    title: t('Navigation.settings')
  }
  if (routingRulesEnabled.value) {
    settings.children = [
      {
        id: 'routing-rules',
        title: t('Navigation.routingRules')
      }
    ]
  }
  let parents: SuiteHeaderNavigationItem[] = [
    {
      id: 'suite-entry',
      title: t('Navigation.shipping'),
      children: [shipping, settings]
    }
  ]
  if (returnbirdAccountId.value) parents.push({ id: returnAuthUrl, title: t('Navigation.return') })

  return parents
})

if (user.value) useIntercomWidget(user.value)
</script>

<template>
  <SuiteAppLayout :headerBarProps :footerBarProps @language-changed="setLocale($event.newLanguage)">
    <template #header-center>
      <SuiteHeaderNavigation :items="buildNavigationItems">
        <template #nav-items="{ navItem, closeNav }">
          <router-link
            v-if="hasRoute(navItem.id)"
            :to="{ name: navItem.id }"
            @click="closeNav"
            active-class="text-sky"
            class="hover:text-sky"
          >
            {{ navItem.title }}
          </router-link>
          <a v-else :href="navItem.id" class="hover:text-sky">
            {{ navItem.title }}
          </a>
        </template>
      </SuiteHeaderNavigation>
    </template>
    <template #header-right v-if="headerDropdownProps">
      <SuiteHeaderDropdown v-bind="headerDropdownProps">
        <template #menu-icon>
          <SuiteIcon icon="User" class="mr-2 size-4" />
        </template>
      </SuiteHeaderDropdown>
    </template>
    <template #default><RouterView /></template>
  </SuiteAppLayout>
</template>
