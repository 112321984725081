import type { Shipment } from '@/api/shipcloud/shipment'
import { addressLines } from '@/helpers'
import type { DetailColumn } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

const additionalServiceName = (name: string, i18n: ReturnType<typeof useI18n>) => {
  const { t } = i18n()
  return t(`AdditionalService.names.${name}`, name)
}

const addSurcharges = (
  column: DetailColumn,
  shipment: Shipment,
  i18n: ReturnType<typeof useI18n>
) => {
  if (!shipment.surcharges) return

  const { n, t } = i18n()
  if (shipment.surcharges.pre_calculated?.length) {
    column.items.push({
      label: t('Office.ShipmentDetails.precalculatedSurcharges')
    })
    shipment.surcharges.pre_calculated.forEach((surcharge) => {
      column.items.push({
        label: surcharge.category,
        value: n(Number(surcharge.amount), 'currency')
      })
    })
  }
  if (shipment.surcharges.post_calculated?.length) {
    column.items.push({
      label: t('Office.ShipmentDetails.postcalculatedSurcharges')
    })
    shipment.surcharges.post_calculated.forEach((surcharge) => {
      column.items.push({
        label: surcharge.category,
        value: n(Number(surcharge.amount), 'currency')
      })
    })
  }
}

const collectAdditionalServiceProperties = (
  properties: Map<string, string>,
  i18n: ReturnType<typeof useI18n>
): string => {
  const { t } = i18n()
  const list: string[] = []
  for (const [key, value] of Object.entries(properties)) {
    const name = t(`AdditionalService.properties.${key}`, key)
    list.push(`${name}: ${value}`)
  }
  return list.join('\n')
}

const addAdditionalServices = (
  column: DetailColumn,
  shipment: Shipment,
  i18n: ReturnType<typeof useI18n>
) => {
  if (!shipment.additional_services) return

  const { t } = i18n()
  const withoutProperties = shipment.additional_services.filter(
    (additionalService) => additionalService.properties == null
  )
  const withProperties = shipment.additional_services.filter(
    (additionalService) => additionalService.properties !== null
  )

  if (withoutProperties.length) {
    column.items.push({
      label: t('Shipment.additional_services'),
      value: withoutProperties
        .map((additionalService) => additionalServiceName(additionalService.name, i18n))
        .join('\n')
    })
  }
  if (withProperties.length) {
    for (const additionalService of withProperties) {
      column.items.push({
        label: additionalServiceName(additionalService.name, i18n),
        value: collectAdditionalServiceProperties(additionalService.properties, i18n)
      })
    }
  }
}

const firstColumn = (shipment: Shipment, i18n: ReturnType<typeof useI18n>): DetailColumn => {
  const { t } = i18n()
  const column = {
    title: t('Office.ShipmentDetails.shipmentDetails'),
    items: [
      {
        label: t('Shipment.from'),
        value: addressLines(shipment.from).join('\n')
      }
    ]
  }
  if (shipment.reference_number) {
    column.items.push({
      label: t('Shipment.reference_number'),
      value: String(shipment.reference_number)
    })
  }
  addAdditionalServices(column, shipment, i18n)
  return column
}

const secondColumn = (shipment: Shipment, i18n: ReturnType<typeof useI18n>): DetailColumn => {
  const { t } = i18n()
  return {
    title: t('Office.ShipmentDetails.customerDetails'),
    items: [
      {
        label: t('Shipment.to'),
        value: shipment?.to ? addressLines(shipment.to).join('\n') : ''
      },
      {
        label: t('Shipment.email'),
        value: shipment.to?.email || ''
      }
    ]
  }
}

const thirdColumn = (shipment: Shipment, i18n: ReturnType<typeof useI18n>): DetailColumn => {
  const { n, t } = i18n()
  const column = {
    title: t('Office.ShipmentDetails.financialDetails'),
    items: [
      {
        label: t('Shipment.price'),
        value: n(Number(shipment.price), 'currency')
      }
    ]
  }
  addSurcharges(column, shipment, i18n)
  return column
}

export const buildDetails = (shipment: Shipment, i18n: ReturnType<typeof useI18n>) => {
  return {
    columns: [
      firstColumn(shipment, i18n),
      secondColumn(shipment, i18n),
      thirdColumn(shipment, i18n)
    ]
  }
}
