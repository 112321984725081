<script setup lang="ts">
import { BackLink } from '@/components'
import SuccessMessagePanel from '@/components/SuccessMessagePanel.vue'
import { useShipmentDetails } from '@/composables/ShipmentDetails'
import { windowOpen } from '@/helpers'
import router from '@/router'
import { useCarriersStore } from '@/stores/carriers'
import { useShipmentsStore } from '@/stores/shipments'
import {
  DialogBox,
  SuiteButton,
  SuiteErrorMessage,
  SuiteItemDetails,
  SuiteLoader,
  ThemeEnum
} from '@shipcloud/suite-components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

export type ShipmentDetailsProps = { id: string }
const props = defineProps<ShipmentDetailsProps>()
const { t } = useI18n()
const shipmentsStore = useShipmentsStore()
const carriersStore = useCarriersStore()
const { build, suiteItemDetailsProps, canBeCopied, canBeReturned } = useShipmentDetails(useI18n)
const shipment = computed(() => shipmentsStore.shipmentById(props.id))
if (!shipment.value) shipmentsStore.fetchShipment(props.id)
if (!carriersStore.hasItems && !carriersStore.loading) carriersStore.fetchCarriers()

watch(
  shipment,
  () => {
    if (shipment.value) build(shipment.value)
  },
  { immediate: true }
)

const deletionSuccess = ref(false)
const deleteDialogOpen = ref(false)
const deleteShipment = async () => {
  deleteDialogOpen.value = false
  const deleted_shipment_data = await shipmentsStore.deleteShipment(props.id)
  if (deleted_shipment_data === true) {
    deletionSuccess.value = true
    setTimeout(() => router.push({ name: 'shipments-dashboard' }), 3000)
  }
}
defineExpose({ deleteShipment })
</script>

<template>
  <DialogBox
    v-model="deleteDialogOpen"
    dialog-size="small"
    :dialog-title="t('Office.ShipmentDetails.shipmentDeletion.ConfirmDeletionDialogHeader')"
  >
    <div class="mt-4 min-w-96 px-4">
      {{ t('Office.ShipmentDetails.shipmentDeletion.ConfirmDeletionDialogText') }}
    </div>
    <div class="flex justify-end gap-2 p-4">
      <SuiteButton @click="deleteDialogOpen = false" :theme="ThemeEnum.BLUE_OUTLINE">
        {{ t('Office.ShipmentDetails.shipmentDeletion.CancelDeletionButtonText') }}
      </SuiteButton>
      <SuiteButton :theme="ThemeEnum.RED_OUTLINE" @click="deleteShipment()">
        {{ t('Office.ShipmentDetails.shipmentDeletion.ConfirmDeletionButtonText') }}
      </SuiteButton>
    </div>
  </DialogBox>

  <SuiteLoader page :loading="shipmentsStore.loading" />
  <template v-if="!shipmentsStore.loading">
    <SuiteErrorMessage :scroll-into-view="true" v-if="shipmentsStore.error" class="rounded-none">
      <div v-for="err in shipmentsStore.errorMessages" :key="err">
        {{ err }}
      </div>
    </SuiteErrorMessage>
    <SuccessMessagePanel v-if="deletionSuccess" auto-hide>
      {{ t('Office.ShipmentDetails.shipmentDeletion.successfullyDeleted') }}
    </SuccessMessagePanel>
    <SuccessMessagePanel v-if="shipmentsStore.inlineReturnId">
      <RouterLink :to="{ name: 'shipment-details', params: { id: shipmentsStore.inlineReturnId } }">
        {{ t('Office.ShipmentForm.inlineReturn') }}
      </RouterLink>
    </SuccessMessagePanel>
    <SuccessMessagePanel v-if="shipmentsStore.saveSuccess" auto-hide>
      {{
        shipment.service == 'returns'
          ? t('Office.ShipmentForm.saveReturnSuccess')
          : t('Office.ShipmentForm.saveSuccess')
      }}
    </SuccessMessagePanel>
    <SuiteItemDetails v-if="suiteItemDetailsProps" v-bind="suiteItemDetailsProps">
      <template #header>
        <BackLink name="shipments-dashboard" />
      </template>
      <template #headerSecondary>
        <div class="-m-3 font-normal">
          <SuiteButton
            id="copy-shipment-button"
            class="group h-10"
            icon="Plus"
            v-if="canBeCopied"
            @click="router.push({ name: 'create-shipment-from-existing', params: { id } })"
          >
            <span class="hidden group-hover:block">
              {{ t('Office.ShipmentDetails.duplicateButton') }}
            </span>
          </SuiteButton>
          <SuiteButton
            v-if="canBeReturned"
            id="create-return-button"
            class="group h-10"
            icon="ParcelCycleCurrentcolor"
            @click="router.push({ name: 'create-return-from-existing', params: { id } })"
          >
            <span class="hidden group-hover:block">
              {{ t('Office.ShipmentDetails.returnButton') }}
            </span>
          </SuiteButton>
          <SuiteButton
            id="delete-shipment-button"
            class="group h-10"
            icon="Delete2"
            icon-class="text-red-500"
            @click="deleteDialogOpen = !deleteDialogOpen"
          >
            <span class="hidden text-red-500 group-hover:block">
              {{ t('Office.ShipmentDetails.deleteButton') }}
            </span>
          </SuiteButton>
        </div>
      </template>
      <template
        #customsDeclarationSummaryEnd
        v-if="shipment?.customs_declaration?.carrier_declaration_document_url"
      >
        <SuiteButton
          icon="FileText"
          class="p-2"
          data-identifier="open-customs-document"
          @click="
            windowOpen(shipment.customs_declaration.carrier_declaration_document_url as string)
          "
        />
      </template>
    </SuiteItemDetails>
  </template>
</template>
