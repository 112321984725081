import { isShipcloudApiError, ShipcloudAPI } from '@/api/shipcloud'
import { logError } from '@/helpers'
import { useCredentialsStore } from '@/stores/credentials'

export const useAuthenticationV1 = () => {
  const store = useCredentialsStore()

  const checkAuthentication = async () => {
    const query = new URLSearchParams(location.search)
    const queryAccessToken = query.get('access_token')
    const queryRefreshToken = query.get('refresh_token')

    if (queryAccessToken) {
      store.accessToken = queryAccessToken.toString()
      location.search = ''
    }
    if (queryRefreshToken) {
      store.refreshToken = queryRefreshToken.toString()
    }

    if (!store.accessToken) {
      const redirectUrl = import.meta.env.VITE_OAUTH_REDIRECT_URI + location.href
      const loginUrl = import.meta.env.VITE_OAUTH_LOGIN_URL + redirectUrl
      location.href = loginUrl
    }
  }

  const checkAuthorization = async (): Promise<boolean> => {
    const shipcloudApi = new ShipcloudAPI()
    return shipcloudApi
      .getMe()
      .then((response) => {
        if (!isShipcloudApiError(response)) {
          store.user = response
          return !!store.suiteFeaturesEnabled
        }
        return false
      })
      .catch((e) => {
        logError(e)
        return false
      })
  }

  return { checkAuthentication, checkAuthorization }
}
