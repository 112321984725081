import type { CustomsDeclaration } from '@/api/shipcloud/shipment'
import { addressLines } from '@/helpers'
import { type DetailColumn } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

const customsDeclarationCurrencyValue = (
  value: string,
  currency: string,
  i18n: ReturnType<typeof useI18n>
) => {
  const { n } = i18n()
  return currency == 'EUR' ? n(Number(value), 'currency') : `${value} ${currency}`
}

const firstColumn = (
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
): DetailColumn => {
  const { d, t } = i18n()
  const items = [
    {
      label: t('CustomsDeclaration.contents_type'),
      value: t(`CustomsDeclaration.contents_types.${customsDeclaration.contents_type}`)
    },
    {
      label: t('CustomsDeclaration.contents_explanation'),
      value: customsDeclaration.contents_explanation || ''
    },
    {
      label: t('CustomsDeclaration.posting_date'),
      value: customsDeclaration.posting_date ? d(customsDeclaration.posting_date) : ''
    },
    {
      label: t('CustomsDeclaration.document_type'),
      value: t(`CustomsDeclaration.document_types.${customsDeclaration.document_type}`)
    }
  ]
  if (customsDeclaration.buyer_address) {
    items.push({
      label: t('CustomsDeclaration.buyer_address'),
      value: addressLines(customsDeclaration.buyer_address).join('\n')
    })
  }
  return {
    title: t('Shipment.customs_declaration'),
    items
  }
}

const secondColumn = (
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
): DetailColumn => {
  const { t } = i18n()
  return {
    title: t('Shipment.customs_declaration'),
    items: [
      {
        label: t('CustomsDeclaration.invoice_number'),
        value: customsDeclaration.invoice_number || ''
      },
      {
        label: t('CustomsDeclaration.exporter_reference'),
        value: customsDeclaration.exporter_reference || ''
      },
      {
        label: t('CustomsDeclaration.importer_reference'),
        value: customsDeclaration.importer_reference || ''
      },
      {
        label: t('CustomsDeclaration.drop_off_location'),
        value: customsDeclaration.drop_off_location || ''
      }
    ]
  }
}

const thirdColumn = (
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
): DetailColumn => {
  const { t } = i18n()
  return {
    title: t('Office.ShipmentDetails.financialDetails'),
    items: [
      {
        label: t('CustomsDeclaration.total_value_amount'),
        value: customsDeclarationCurrencyValue(
          customsDeclaration.total_value_amount,
          customsDeclaration.currency,
          i18n
        )
      },
      {
        label: t('CustomsDeclaration.additional_fees'),
        value: customsDeclaration.additional_fees
          ? customsDeclarationCurrencyValue(
              customsDeclaration.additional_fees,
              customsDeclaration.currency,
              i18n
            )
          : ''
      }
    ]
  }
}

export const buildCustomsDetails = (
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
) => {
  return {
    columns: [
      firstColumn(customsDeclaration, i18n),
      secondColumn(customsDeclaration, i18n),
      thirdColumn(customsDeclaration, i18n)
    ]
  }
}
