<script setup lang="ts">
import { useI18n } from 'vue-i18n'

export type TrackingLinkProps = { url?: string }
defineProps<TrackingLinkProps>()
const { t } = useI18n()
</script>

<template>
  <a
    v-if="url?.length"
    class="hover:text-sky flex items-center gap-2"
    :href="url"
    target="_blank"
    :title="t('Office.ShipmentDetails.linkToCarrier')"
  >
    <slot />
  </a>
  <slot v-else />
</template>
