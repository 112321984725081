<script setup lang="ts">
import type { Package, Shipment } from '@/api/shipcloud/shipment'
import type { SuiteEventTimelineEvent } from '@shipcloud/suite-components'
import { SuiteEventTimeline } from '@shipcloud/suite-components'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

type TrackingDetailsProps = {
  pkg: Package
  shipment: Shipment
}
const props = defineProps<TrackingDetailsProps>()

const { d, t } = useI18n()
const events = computed<SuiteEventTimelineEvent[]>(() => {
  if (!props.pkg.tracking_events) return []

  return props.pkg.tracking_events.reduce((events, trackingEvent) => {
    events.push({
      title: trackingEvent.location,
      id: trackingEvent.id,
      timestamp: d(trackingEvent.timestamp),
      status: trackingEvent.status,
      body: trackingEvent.details
    })
    return events
  }, [] as SuiteEventTimelineEvent[])
})
</script>
<template>
  <SuiteEventTimeline v-if="events.length" :events="events" />
  <span v-else>{{ t('TrackingEvent.no_events') }}</span>
</template>
