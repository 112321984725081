import type { Delivery } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { deliveriesToSuiteTableNestedBody } from '../../deliveriesToSuiteTableNested/body'
import { deliveriesToSuiteTableNestedHead } from '../../deliveriesToSuiteTableNested/head'

export const deliveryToSuiteItemDetailsCustomsDeclaration = (
  masterCartonDeliveries: Delivery[],
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>
): SuiteItemDetailsProps['customsDeclaration'] => {
  if (!masterCartonDeliveries.length) return undefined

  const tableBody = deliveriesToSuiteTableNestedBody(
    masterCartonDeliveries,
    'deliveries',
    i18n,
    router
  )

  if (!tableBody) return undefined

  return {
    table: {
      body: tableBody,
      head: deliveriesToSuiteTableNestedHead(i18n)
    }
  }
}
