import { z } from 'zod'
import { Address, apiCall, ApiError } from '.'

export const ReferenceNumber = z.object({
  number: z.string(),
  type: z.number()
})
export const ReferenceNumberOptions = z.object({
  deliveryNote: z.string().optional(),
  orderNumber: z.string().optional(),
  customerNumber: z.string().optional(),
  customer: z.string().optional(),
  other: z.string().optional(),
  your: z.string().optional(),
  invoice: z.string().optional()
})
export const REFERENCE_NUMBER_TYPES: Record<string, number> = {
  deliveryNote: 1,
  orderNumber: 2,
  customerNumber: 3,
  customer: 4,
  other: 5,
  your: 6,
  invoice: 7
} as const

export const DeliveryItem = z.object({
  dimensions: z
    .string()
    .array()
    .refine((value) => value.length == 3),
  packagingType: z.enum(['carton']),
  weight: z.string()
})
export const DeliveryType = z.object({
  productCode: z.string()
})
export const RequestDelivery = z.object({
  addresses: Address.array(),
  clientCode: z.string(),
  deliveryNumber: z.string(),
  deliveryType: DeliveryType,
  referenceNumbers: ReferenceNumber.array().optional(),
  items: DeliveryItem.array().refine((value) => value.length == 1)
})
// necessary, to be used in the form (but is it tho?)
export const FormDelivery = RequestDelivery.omit({ addresses: true }).extend({
  deliveryAddress: Address,
  pickupAddress: Address
})
export const OUTPUT_FORMATS = [
  'pdf_a4',
  'pdf_a5',
  'pdf_a6',
  'pdf_a7',
  'pdf',
  'png',
  'zpl203_4x6',
  'zpl203_4x8',
  'zpl203',
  'zpl300_4x6',
  'zpl300_4x8',
  'zpl300'
] as const
export const CreateDeliveryRequest = z.object({
  outputFormat: z.enum(OUTPUT_FORMATS),
  deliveries: RequestDelivery.array()
})
export const CreateDeliveryResponse = z.object({
  deliveries: z.object({ id: z.string() }).array()
})

export type ReferenceNumber = z.infer<typeof ReferenceNumber>
export type ReferenceNumberOptions = z.infer<typeof ReferenceNumberOptions>
export type DeliveryItem = z.infer<typeof DeliveryItem>
export type DeliveryType = z.infer<typeof DeliveryType>
export type RequestDelivery = z.infer<typeof RequestDelivery>
export type FormDelivery = z.infer<typeof FormDelivery>
export type CreateDeliveryRequest = z.infer<typeof CreateDeliveryRequest>
export type CreateDeliveryResponse = z.infer<typeof CreateDeliveryResponse>

export const defaultFormDelivery = (clientCode?: string): FormDelivery => {
  const payload = {
    clientCode: clientCode || '',
    deliveryType: { productCode: '' },
    deliveryAddress: { role: 'delivery' },
    pickupAddress: { role: 'pickup' },
    deliveryNumber: '',
    items: [{ packagingType: 'carton', dimensions: ['', '', ''], weight: '' }]
  }
  return FormDelivery.parse(payload)
}

export const createDelivery = (token: string, request: CreateDeliveryRequest) =>
  apiCall({
    token,
    method: 'POST',
    path: 'deliveries',
    data: request
  }) as unknown as CreateDeliveryResponse | ApiError
