<script setup lang="ts">
import { ShipmentFormActions, ShipmentFormLayout } from '@/components/ShipmentForm'
import { useShipment } from '@/composables/useShipment'
import { SuiteButton, SuitePageHeader, ThemeEnum } from '@shipcloud/suite-components'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = defineProps<{
  id?: string
  createReturn: boolean
}>()
const { t } = useI18n()
const router = useRouter()

const {
  additionalServices,
  additionalServiceErrors,
  carrierFilterOptions,
  inlineReturn,
  saveShipment,
  saving,
  carrierSupportsReturns,
  returnCarrierFilterOptions,
  serviceFilterOptions,
  shipment,
  showCustomsDeclaration,
  shipmentQuoteInfo,
  shipmentQuoteFetch,
  shipmentQuoteLoading,
  loading,
  error,
  newShipment
} = useShipment()
watch(props, () => newShipment(props.createReturn, props.id), { immediate: true })

const renderInlineReturn = ref(false)
watch(
  () => shipment.value?.carrier,
  (_newVal, oldVal) => {
    if (shipment.value) {
      if (!props.createReturn) {
        if (oldVal) shipment.value.service = ''
        renderInlineReturn.value = carrierSupportsReturns(shipment.value.carrier)
      }
    }
  }
)

const save = async () => {
  const id = await saveShipment()
  if (id) router.push({ name: 'shipment-details', params: { id }, replace: true })
}

defineExpose({ shipment })
</script>

<template>
  <template v-if="createReturn">
    <SuitePageHeader
      :header="t('Office.ShipmentForm.returnPageHeader')"
      :subheader="t('Office.ShipmentForm.returnPageSubheader')"
    />

    <ShipmentFormLayout
      v-model="shipment"
      :left-address="{
        apiModelName: 'Sender',
        attribute: 'from',
        title: t('Office.ShipmentForm.returnFrom'),
        opened: true
      }"
      :right-address="{
        apiModelName: 'Receiver',
        attribute: 'to',
        title: t('Office.ShipmentForm.returnTo'),
        opened: false
      }"
      :loading
      :saving
      :shipment-quote-loading
      :show-customs-declaration="false"
      :error
    >
      <ShipmentFormActions
        v-model:shipment="shipment"
        :carrier-filter-options="returnCarrierFilterOptions"
        :saving
        :error
        :shipment-quote-loading
        :shipment-quote-info
        @save="save"
        @shipment-quote-fetch="shipmentQuoteFetch"
      />
    </ShipmentFormLayout>
  </template>
  <template v-else>
    <SuitePageHeader
      :header="t('Office.ShipmentForm.outboundPageHeader')"
      :subheader="t('Office.ShipmentForm.outboundPageSubheader')"
    >
      <template v-if="!props.id" #aside>
        <SuiteButton
          :theme="ThemeEnum.BLUE_OUTLINE"
          class=""
          @click="
            router.push({
              name: 'create-return'
            })
          "
        >
          {{ t('Office.ShipmentForm.createReturnButton') }}
        </SuiteButton>
      </template>
    </SuitePageHeader>
    <ShipmentFormLayout
      v-model="shipment"
      :left-address="{
        apiModelName: 'Receiver',
        attribute: 'to',
        title: t('Office.ShipmentForm.shipTo'),
        opened: true
      }"
      :right-address="{
        apiModelName: 'Sender',
        attribute: 'from',
        title: t('Office.ShipmentForm.shipFrom'),
        opened: false
      }"
      :loading
      :saving
      :shipment-quote-loading
      :show-customs-declaration
      :error
    >
      <ShipmentFormActions
        v-model:shipment="shipment"
        v-model:inline-return="inlineReturn"
        :additional-services
        :additional-service-errors
        :shipment-quote-info
        :carrier-filter-options
        :service-filter-options
        :saving
        :error
        :shipment-quote-loading
        :render-inline-return
        @save="save"
        @shipment-quote-fetch="shipmentQuoteFetch"
      />
    </ShipmentFormLayout>
  </template>
</template>
