import { toQueryParams } from '@/helpers'
import { z } from 'zod'
import { Address } from './address'
import { apiCall, paginatedApiCall } from './client'
import {
  DeliveryItem,
  getLastShipmentFromDeliveryItem,
  getMasterCartonShipmentFromDeliveryItem
} from './deliveryItem'
import { MILESTONES } from './event'
import { type Pagination } from './shared'

export const Delivery = z.object({
  id: z.string(),
  deliveryNumber: z.string(),
  clientCode: z.string(),
  clientName: z.string(),
  createdAt: z.string(),
  shippingDate: z.string(),
  instruction: z.string().optional(),
  comment: z.string().optional(),
  to: Address.optional(),
  from: Address.optional(),
  deliveryItems: DeliveryItem.array()
})
export type Delivery = z.infer<typeof Delivery>
export const Deliveries = z.array(Delivery)
export type Deliveries = z.infer<typeof Deliveries>

export const DELIVERY_TYPES = ['deliveries', 'masterCarton'] as const
export type DeliveryType = (typeof DELIVERY_TYPES)[number]
export const DeliveryFilter = z.object({
  containerId: z.string().optional(),
  carrierCode: z.string().optional(),
  createdAt: z.string().optional(),
  deliveryNumber: z.string().optional(),
  milestone: z.enum(MILESTONES).optional(),
  trackingNumber: z.string().optional(),
  type: z.enum(DELIVERY_TYPES).optional()
})
export type DeliveryFilter = z.infer<typeof DeliveryFilter>

export const GetDeliveries = (filter: DeliveryFilter, pagination: Pagination) => {
  const { type, ...apiFilter } = filter
  if (type === 'masterCarton') Object.assign(apiFilter, { transportStepKind: 'LinehaulParcel' })
  return paginatedApiCall(
    {
      path: `rest/api/accounts/deliveries?orderBy=createdAt&orderByDescending=true&${toQueryParams(apiFilter, pagination).toString()}`,
      method: 'GET'
    },
    Delivery
  )
}

export const GetDelivery = (id: string) =>
  apiCall({
    path: `rest/api/accounts/deliveries/${id}`,
    method: 'GET'
  })

export const getMasterCartonShipmentFromDelivery = (delivery: Delivery) => {
  let masterCartonShipment = undefined
  for (let i = 0, t = delivery.deliveryItems.length; i < t; i++) {
    masterCartonShipment = getMasterCartonShipmentFromDeliveryItem(delivery.deliveryItems[i])
    if (masterCartonShipment) break
  }
  return masterCartonShipment
}

export const getLastShipmentFromDelivery = (delivery: Delivery) => {
  let lastShipment = undefined
  for (let i = 0, t = delivery.deliveryItems.length; i < t; i++) {
    lastShipment = getLastShipmentFromDeliveryItem(delivery.deliveryItems[i])
    if (lastShipment) break
  }
  return lastShipment
}
