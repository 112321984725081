import type { Shipment, TrackingStatus } from '@/api/shipcloud/shipment'
import { addressLines, lastTrackingEvent, trackingStatusColors } from '@/helpers'
import {
  StatusCaption,
  SuiteButton,
  SuiteCarrierLogo,
  SuiteFlag,
  type SuiteTableNestedRow
} from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'

const formatDate = (date: string) => {
  const dateObj = new Date(date)
  return (
    ('0' + dateObj.getUTCDate()).slice(-2) +
    '-' +
    ('0' + (dateObj.getUTCMonth() + 1)).slice(-2) +
    '-' +
    dateObj.getUTCFullYear()
  )
}

const getRecipient = (shipment: Shipment) => {
  const companyOrLastName = shipment.to?.company ? 'company' : 'last_name'

  return shipment.to && addressLines(shipment.to, [companyOrLastName, 'zip_code', 'city']).join(' ')
}

const getTrackingStatus = (shipment: Shipment, index: number = 0) => {
  return lastTrackingEvent(shipment.packages[index])?.status || 'ignored'
}

const getShipmentTrackingStatus = (shipment: Shipment): TrackingStatus => {
  const firstStatus: TrackingStatus = getTrackingStatus(shipment)
  let sameStatus = true
  let problemStatus = false

  for (let i = 0; i < shipment?.packages?.length; i++) {
    const status = getTrackingStatus(shipment, i)

    if (status !== firstStatus) {
      sameStatus = false
    }

    if (['destroyed', 'exception', 'not_delivered', 'exception'].includes(status)) {
      problemStatus = true
    }
  }

  if (sameStatus) {
    return firstStatus
  }

  if (problemStatus) {
    return 'exception'
  }

  return 'transit'
}

export const shipmentsToSuiteTableNestedBody = (
  shipments: Shipment[],
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>
) => {
  if (!shipments.length) return undefined

  const { t } = i18n

  return shipments.reduce((acc, shipment, shipmentIndex) => {
    const shipmentTrackingStatus = getShipmentTrackingStatus(shipment)
    const isMultiColli = shipment.packages.length > 1
    const isOddRow = shipmentIndex % 2 !== 0

    const row: SuiteTableNestedRow = {
      attrs: {
        class: ['hover:bg-blue-200', { 'bg-blue-100': isOddRow }]
      },
      cells: [
        isMultiColli
          ? {
              content: shipment.carrier_tracking_no || '',
              isMultiColli,
              packagesCount: shipment.packages.length
            }
          : shipment.carrier_tracking_no || '',
        {
          component: StatusCaption,
          content: t(`TrackingEvent.status.${shipmentTrackingStatus}`),
          props: { color: trackingStatusColors(shipmentTrackingStatus) }
        },
        {
          component: SuiteCarrierLogo,
          props: {
            carrier: shipment.carrier,
            class: 'max-h-24 max-w-16'
          }
        },
        t(`Shipment.services.${shipment.service}`),
        formatDate(shipment.created_at),
        getRecipient(shipment),
        {
          component: SuiteFlag,
          props: {
            country: shipment.to?.country
          }
        },
        {
          component: SuiteButton,
          props: {
            icon: 'ZoomIn',
            onClick: () =>
              router.push({
                name: 'shipment-details',
                params: { id: shipment.id }
              }),
            'data-identifier': 'details-link'
          }
        }
      ]
    }

    if (isMultiColli) {
      row.children = shipment.packages.map((pkg, pkgIndex): SuiteTableNestedRow => {
        const packageTrackingStatus = getTrackingStatus(shipment, pkgIndex)
        return {
          attrs: {
            class: { 'hover:bg-blue-200': true, 'bg-blue-100': isOddRow }
          },
          cells: [
            {
              class: 'whitespace-nowrap',
              content:
                pkg?.carrier_tracking_no || `${shipment?.carrier_tracking_no} (${pkgIndex + 1})`
            },
            {
              component: StatusCaption,
              content: t(`TrackingEvent.status.${packageTrackingStatus}`),
              props: { color: trackingStatusColors(packageTrackingStatus) }
            },
            '',
            '',
            '',
            '',
            '',
            ''
          ]
        }
      })
    }

    acc.push(row)

    return acc
  }, [] as SuiteTableNestedRow[])
}
