<script setup lang="ts">
import { FormDelivery, uneditableAddressProperties } from '@/api/apiLayer'
import { AddressForm } from '@/components'
import {
  SuiteAccordion,
  SuiteButton,
  SuiteCollapser,
  SuiteIcon,
  SuiteInput,
  SuiteInputLabel,
  ThemeEnum
} from '@shipcloud/suite-components'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { DeliveryFormReferenceNumbers } from '.'

const delivery = defineModel<FormDelivery>()
const { t } = useI18n()
const error = ref()
const collapser1 = ref(true)
const collapser2 = ref(false)
const collapser3 = ref(false)
</script>

<template>
  <SuiteAccordion v-if="delivery">
    <SuiteCollapser v-model:open="collapser1" :no-padding="true">
      <template #header>
        <span class="text-xl font-bold">{{ t('DeliveryForm.addresses') }}</span>
      </template>
      <div class="px-4 pb-4">
        <div
          class="grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2"
        >
          <AddressForm
            v-if="delivery.deliveryAddress"
            api-model-name="delivery"
            v-model="delivery.deliveryAddress"
            :excluded-properties="uneditableAddressProperties"
            :title="t('DeliveryForm.deliveryAddress')"
            :error
          />
          <AddressForm
            v-if="delivery.pickupAddress"
            api-model-name="pickup"
            v-model="delivery.pickupAddress"
            :excluded-properties="uneditableAddressProperties"
            :title="t('DeliveryForm.pickupAddress')"
            :error
          />
        </div>
        <div class="mt-5 flex justify-end gap-3">
          <SuiteButton id="details-to-description" class="px-5" @click="() => (collapser2 = true)">
            {{ t('DeliveryForm.next') }}
          </SuiteButton>
        </div>
      </div>
    </SuiteCollapser>
    <SuiteCollapser v-model:open="collapser2" no-padding>
      <template #header>
        <span class="text-xl font-bold">{{ t('DeliveryForm.details') }}</span>
      </template>
      <div class="space-y-2 px-4 pb-4">
        <SuiteInputLabel class="grid grid-cols-1 min-[440px]:grid-cols-[8em_2fr]">
          <template #label>{{ t('ParcelCore.deliveryNumber') }}</template>
          <SuiteInput
            id="delivery-number"
            class="w-full max-w-xl"
            v-model="delivery.deliveryNumber"
          />
        </SuiteInputLabel>
        <DeliveryFormReferenceNumbers v-model="delivery" />
      </div>
      <div class="m-5 flex justify-end gap-3">
        <SuiteButton
          class="px-5"
          id="description-to-details"
          :theme="ThemeEnum.BLUE_OUTLINE"
          @click="() => (collapser1 = true)"
        >
          {{ t('Office.ShipmentForm.back') }}
        </SuiteButton>
        <SuiteButton class="px-5" id="description-to-packages" @click="() => (collapser3 = true)">
          {{ t('Office.ShipmentForm.next') }}
        </SuiteButton>
      </div>
    </SuiteCollapser>
    <SuiteCollapser v-model:open="collapser3" no-padding>
      <template #header>
        <span class="text-xl font-bold">{{ t('DeliveryForm.deliveryItems') }}</span>
      </template>
      <div class="px-4 pb-4">
        <div class="text-sm leading-relaxed whitespace-break-spaces text-gray-600">
          {{ t('Office.ShipmentForm.packagesHint') }}
        </div>
        <div class="mt-5 grid justify-start">
          <div>
            <div class="leading-tight font-bold text-gray-600">
              {{ t('Package.dimensions') }}
            </div>
            <div class="py-2 text-sm text-gray-600">
              {{ t('Office.ShipmentForm.packageDimensionsHint') }}
            </div>
            <div class="grid grid-cols-1 gap-5 md:flex md:grid-cols-none">
              <SuiteInputLabel label-position="top">
                <template #label>{{ t(`DeliveryForm.length`) }}</template>
                <SuiteInput
                  id="item-length"
                  type="number"
                  v-model="delivery.items[0].dimensions[0]"
                />
              </SuiteInputLabel>
              <SuiteIcon icon="X" class="mt-8 hidden size-4 md:block" />
              <SuiteInputLabel label-position="top">
                <template #label>{{ t(`DeliveryForm.width`) }}</template>
                <SuiteInput
                  id="item-width"
                  type="number"
                  v-model="delivery.items[0].dimensions[1]"
                />
              </SuiteInputLabel>
              <SuiteIcon icon="X" class="mt-8 hidden size-4 md:block" />
              <SuiteInputLabel label-position="top">
                <template #label>{{ t(`DeliveryForm.height`) }}</template>
                <SuiteInput
                  id="item-height"
                  type="number"
                  v-model="delivery.items[0].dimensions[2]"
                />
              </SuiteInputLabel>
            </div>
          </div>
          <div class="py-2">
            <SuiteInputLabel label-position="top">
              <template #label>
                {{ t(`DeliveryForm.weight`) }}
              </template>
              <SuiteInput id="item-weight" type="number" v-model="delivery.items[0].weight" />
            </SuiteInputLabel>
          </div>
        </div>
        <div class="mt-5 flex justify-end">
          <SuiteButton
            class="px-5"
            id="packages-to-description"
            :theme="ThemeEnum.BLUE_OUTLINE"
            @click="() => (collapser2 = true)"
          >
            {{ t('Office.ShipmentForm.back') }}
          </SuiteButton>
        </div>
      </div>
    </SuiteCollapser>
  </SuiteAccordion>
</template>
