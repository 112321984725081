import { z } from 'zod'
import { Event } from './event'

export const ShipmentItem = z.object({
  id: z.string(),
  containerId: z.string().optional(),
  shipmentItemNumber: z.string(),
  trackingNumber: z.string().optional(),
  events: Event.array()
})
export type ShipmentItem = z.infer<typeof ShipmentItem>
