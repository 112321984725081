<script setup lang="ts">
import {
  FormDelivery,
  REFERENCE_NUMBER_TYPES,
  ReferenceNumber,
  ReferenceNumberOptions
} from '@/api/apiLayer'
import { SuiteFilter } from '@shipcloud/suite-components'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { buildReferenceNumberProps } from './formBuilder'

const delivery = defineModel<FormDelivery>()
const referenceNumbers = ref<ReferenceNumberOptions>({})
const referenceNumberProps = buildReferenceNumberProps(useI18n)
const { t } = useI18n()

const syncFromDelivery = () => {
  if (!delivery.value?.referenceNumbers || !referenceNumbers.value) return

  Object.entries(delivery.value.referenceNumbers).forEach(([_, { type, number }]) => {
    const typeName = Object.entries(REFERENCE_NUMBER_TYPES).find(([_x, id]) => id == type)
    if (typeName) {
      referenceNumbers.value[typeName[0] as keyof ReferenceNumberOptions] = number
    }
  })
}
const syncToDelivery = () => {
  if (!delivery.value) return

  const sync: ReferenceNumber[] = []
  Object.entries(referenceNumbers.value).map(([typeName, number]) => {
    const value = { type: REFERENCE_NUMBER_TYPES[typeName], number }
    sync.push(value)
  })
  if (sync.length) delivery.value.referenceNumbers = sync
  else delete delivery.value.referenceNumbers // the API fails if we send an empty array
}
watch(delivery, syncFromDelivery, { deep: true, immediate: true })
</script>

<template>
  <p class="font-bold text-gray-600">
    {{ t('DeliveryForm.referenceNumbers') }}
  </p>
  <SuiteFilter
    id="reference-numbers"
    v-bind="referenceNumberProps"
    v-model="referenceNumbers"
    @update:model-value="syncToDelivery"
  />
</template>
