<script lang="ts" setup>
import { Delivery, GetDelivery, getMasterCartonShipmentFromDelivery } from '@/api/parcelCore'
import { BackLink } from '@/components'
import { useDeliveriesStore } from '@/stores/parcelCore'
import { SuiteErrorMessage, SuiteItemDetails, SuiteLoader } from '@shipcloud/suite-components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { deliveryToSuiteItemDetails } from './helpers'

type MasterCartonDeliveryDetailsProps = { id: string }
const props = defineProps<MasterCartonDeliveryDetailsProps>()

const deliveriesStore = useDeliveriesStore()
const router = useRouter()

const delivery = ref<Delivery>()
const loading = ref(false)
const error = ref()
watch(
  props,
  async () => {
    loading.value = true
    delivery.value = undefined
    error.value = undefined

    const result = await GetDelivery(props.id)

    if ('status' in result) {
      error.value = result
    } else {
      delivery.value = result
    }
    loading.value = false
  },
  { immediate: true }
)

const masterCartonShipment = computed(() =>
  delivery.value ? getMasterCartonShipmentFromDelivery(delivery.value) : undefined
)

watch(
  () => masterCartonShipment.value,
  (shipment) =>
    shipment?.shipmentItem?.containerId &&
    deliveriesStore.fetchAll(
      { containerId: shipment.shipmentItem.containerId },
      { pageNumber: 1, pageSize: 50 }
    )
)

const i18n = useI18n()
const { t } = i18n
const detailsProps = computed(() =>
  deliveryToSuiteItemDetails({
    delivery: delivery.value,
    i18n,
    isMasterCarton: true,
    mainShipment: masterCartonShipment.value,
    masterCartonDeliveries: Object.values(deliveriesStore.deliveries),
    router
  })
)
</script>

<template>
  <SuiteLoader page :loading />
  <template v-if="!loading">
    <SuiteErrorMessage class="rounded-none" v-if="error">{{
      t('App.Error.fetchShipmentError')
    }}</SuiteErrorMessage>
    <SuiteItemDetails v-else-if="detailsProps" v-bind="detailsProps">
      <template #header>
        <BackLink name="deliveries-dashboard" />
      </template>
    </SuiteItemDetails>
  </template>
</template>
