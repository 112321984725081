<script lang="ts" setup>
import { createDelivery, type FormDelivery } from '@/api/apiLayer'
import { BackLink } from '@/components'
import { useAuthenticationV2Store } from '@/stores/authenticationV2'
import {
  SuiteContentPanel,
  SuiteErrorMessage,
  SuiteLoader,
  SuitePageHeader,
  SuitePanelHeader
} from '@shipcloud/suite-components'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { DeliveryFormAside, DeliveryFormMain, formDeliveryToRequest, initDelivery } from '.'

type DeliveryFormProps = { id?: string }
const props = defineProps<DeliveryFormProps>()
const delivery = defineModel<FormDelivery>()
const authenticationStore = useAuthenticationV2Store()

watch(
  () => props.id,
  () =>
    initDelivery(props.id, authenticationStore.platform('SCOUT')?.data_reference).then(
      (formDelivery) => (delivery.value = formDelivery)
    ),
  { immediate: true }
)

const { t } = useI18n()
const router = useRouter()
const loading = ref(false)
const error = ref()

const saveDelivery = async (token: string, outputFormat: string) => {
  if (!delivery.value) return

  const request = formDeliveryToRequest(delivery.value, outputFormat)
  const response = await createDelivery(token, request)
  if ('deliveries' in response) {
    const id = response.deliveries[0].id
    if (id) router.push({ name: 'delivery-details', params: { id } })
  } else return (error.value = response)
}
</script>

<template>
  <SuiteLoader page :loading />
  <SuitePageHeader :header="t('DeliveryForm.header')" :subheader="t('DeliveryForm.subheader')" />
  <SuiteErrorMessage :scroll-into-view="true" v-if="error" class="rounded-none">
    <div class="mx-auto max-w-(--breakpoint-2xl) px-8">
      {{ t('DeliveryForm.error') }}
      {{ error }}
    </div>
  </SuiteErrorMessage>
  <SuiteContentPanel v-if="!loading">
    <SuitePanelHeader>
      <BackLink name="deliveries-dashboard" />
    </SuitePanelHeader>
    <div class="bg-white">
      <DeliveryFormMain v-model="delivery" />
    </div>
    <template #aside>
      <DeliveryFormAside v-model="delivery" @save="saveDelivery" />
    </template>
  </SuiteContentPanel>
</template>
