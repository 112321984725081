<script setup lang="ts">
import type { ShipcloudApiError } from '@/api/shipcloud'
import { errorOn } from '@/api/shipcloud'
import { countries as countriesObject } from '@/i18n'
import {
  SuiteComboBox,
  SuiteIcon,
  SuiteInput,
  SuiteInputLabel,
  type SuiteComboBoxProps
} from '@shipcloud/suite-components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    error?: ShipcloudApiError
    excludedProperties: readonly string[]
    apiModelName: string
    title: string
    opened?: boolean
    editButton?: boolean
    removeButton?: boolean
  }>(),
  { opened: true, editButton: false, removeButton: false }
)
type AddressFormModel = Record<string, string | null>
const address = defineModel<AddressFormModel>()
const open = ref(props.opened)
watch(
  open,
  (newVal) => {
    if (newVal) {
      address.value ??= {}
      if (address.value.id) address.value.id = null
    }
  },
  { immediate: true }
)
const countryOptions: SuiteComboBoxProps['options'] = Object.entries(countriesObject).map(
  ([value, label]) => ({ value, label })
)
const reset = () => (address.value = undefined)
const inputId = (property: string) =>
  `${props.apiModelName.toLowerCase().replace(' ', '-')}-${property.replace('_', '-')}`
const editableAddressProperties = computed(
  () =>
    address.value &&
    Object.keys(address.value).filter((field) => !props.excludedProperties.includes(field))
)
</script>

<template>
  <div>
    <div class="mb-3 inline-flex items-center justify-start gap-2 text-gray-600">
      <div class="bg-sky bg-opacity-50 h-4 w-1"></div>
      <div class="text-sm leading-snug font-bold">
        {{ title }}
      </div>
      <div v-if="editButton && !open" :id="inputId('edit')" @click="open = true">
        <SuiteIcon icon="Edit" class="w-4 cursor-pointer" />
      </div>
      <div v-if="removeButton" :id="inputId('remove')" @click="reset()">
        <SuiteIcon icon="X" class="w-4 cursor-pointer text-red-500" />
      </div>
    </div>
    <div class="grid grid-cols-1 gap-2" v-if="address">
      <template v-for="property in editableAddressProperties" :key="property">
        <SuiteInputLabel class="">
          <template #label>
            <div
              class="w-32"
              :class="{ 'text-red-300': errorOn(error, { model: apiModelName, property }) }"
            >
              {{ t(`Address.${property}`) }}
            </div>
          </template>
          <template v-if="open">
            <SuiteComboBox
              v-if="property == 'country'"
              :id="inputId(property)"
              :name="inputId(property)"
              v-model="address['country'] as string"
              :options="countryOptions"
              :placeholder="t('Address.country')"
              class="w-80 xl:max-w-52"
              :class="{ 'border-form-error': errorOn(error, { model: apiModelName, property }) }"
            />
            <SuiteInput
              v-else
              :id="inputId(property)"
              :placeholder="t(`Address.${property}`)"
              v-model="address[property]"
              class="w-80 xl:max-w-52"
              :class="{ 'border-red-300': errorOn(error, { model: apiModelName, property }) }"
            />
          </template>
          <div v-else class="text-xs leading-normal font-medium text-gray-900 md:pl-3">
            {{ address[property] }}
          </div>
        </SuiteInputLabel>
      </template>
    </div>
  </div>
</template>
