import {
  DELIVERIES_TRANSPORT_STEP_KINDS,
  filterShipmentsByTransportStepkind,
  isMasterCarton,
  MASTER_CARTON_TRANSPORT_STEP_KIND,
  type Delivery,
  type DeliveryItem,
  type Shipment
} from '@/api/parcelCore'
import { TrackingLink } from '@/components'
import { DeliveryItemShipment } from '@/components/parcelCore'
import { weight } from '@/helpers'
import type { SuiteTableNestedRow } from '@shipcloud/suite-components'
import { markRaw } from 'vue'
import type { useI18n } from 'vue-i18n'
import { createRouter, useRouter } from 'vue-router'

const rawComponentDeliveryItemShipment = markRaw(DeliveryItemShipment)

const formatDimensions = (item: DeliveryItem, i18n: ReturnType<typeof useI18n>) => {
  const { n } = i18n
  return [n(item.height), n(item.width), n(item.length)]
    .sort()
    .reverse()
    .join(' cm x ')
    .concat(' cm')
}

const trackingNumberCell = (shipment: Shipment) => {
  return {
    component: markRaw(TrackingLink),
    content: shipment.trackingNumber,
    props: { url: shipment.trackingLink }
  }
}

const getItemChildren = (
  shipments: Shipment[],
  delivery: Delivery,
  router: ReturnType<typeof useRouter>,
  masterCarton = false
) => {
  if (!shipments.length) return undefined
  return shipments.map((shipment) => ({
    cells: [
      {
        colspan: 99,
        component: rawComponentDeliveryItemShipment,
        props: {
          shipment,
          showDetails: !masterCarton,
          onViewMasterCartonDelivery: () =>
            router.push({
              name: 'master-carton-delivery-details',
              params: { id: delivery.id }
            })
        }
      }
    ]
  }))
}

export const deliveryToSuiteItemDetailsTableBody = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>,
  masterCarton = false
) => {
  const { n, t } = i18n
  if (!delivery?.deliveryItems?.length) return undefined

  return delivery.deliveryItems.map((item): SuiteTableNestedRow => {
    const filteredShipments = filterShipmentsByTransportStepkind(
      item.shipments,
      masterCarton
        ? [MASTER_CARTON_TRANSPORT_STEP_KIND]
        : DELIVERIES_TRANSPORT_STEP_KINDS.concat(MASTER_CARTON_TRANSPORT_STEP_KIND)
    )
    const masterCartonShipment = masterCarton ? false : filteredShipments.find(isMasterCarton)
    return {
      cells: [
        masterCartonShipment
          ? t('ParcelCore.multiLegDelivery')
          : trackingNumberCell(filteredShipments[0]),
        formatDimensions(item, i18n),
        weight(n(item.weight)),
        item.packagingType,
        filteredShipments[0].shipmentItem.events[0].status
      ],
      attrs: {
        class: 'font-bold'
      },
      children: getItemChildren(filteredShipments, delivery, router, masterCarton)
    }
  })
}
