<script setup lang="ts">
import type { PaginationOptions } from '@/api/shipcloud'
import type { PickupRequest } from '@/api/shipcloud/pickupRequests'
import { BackLink } from '@/components'
import { addressLines } from '@/helpers'
import router from '@/router'
import { useCarriersStore } from '@/stores/carriers'
import { usePickupRequestsStore } from '@/stores/pickupRequests'
import { useShipmentsStore } from '@/stores/shipments'
import {
  SuiteErrorMessage,
  SuiteLoader,
  SuitePageHeader,
  SuitePickupRequests,
  type SuitePaginationEventUpdate,
  type SuitePickupRequestsProps
} from '@shipcloud/suite-components'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t, n, d } = useI18n()

type ShipmentTableRow = {
  carrier: string
  packagesCount: number
  weight: string
  carrierId: string
}
type PickupRequestTableRow = {
  id: string
  address: string
  carrier: string
  packagesCount: number
  pickupDate: string
}
type PickupRequestsPaginationProps = NonNullable<SuitePickupRequestsProps['requestsPagination']>

const shipments = ref<ShipmentTableRow[]>([])
const requests = ref<PickupRequestTableRow[]>([])
const carriersStore = useCarriersStore()
const shipmentsStore = useShipmentsStore()
const pickupRequestsStore = usePickupRequestsStore()
const shipmentsColumnHeadings = {
  carrier: t('Office.PickupRequestsDashboard.carrier'),
  packagesCount: t('Office.PickupRequestsDashboard.packagesCount'),
  weight: t('Office.PickupRequestsDashboard.weight')
}
const requestsPagination = ref<PickupRequestsPaginationProps>({
  itemCount: 0,
  pageSizeOptions: [25, 50, 100],
  page: 1,
  pageSize: 25
})
const paginationOptions = ref<PaginationOptions>({
  page: 1,
  per_page: 25
})

const shipmentTableRow = (carrierId: string): ShipmentTableRow => {
  const carrier = t(`Shipment.carriers.${carrierId}`, carrierId)
  let packagesCount = 0
  let packagesWeight = 0
  Object.values(shipmentsStore.shipments).forEach((shipment) => {
    packagesCount += shipment.packages.length
    shipment.packages.forEach((pkg) => (packagesWeight += pkg.weight))
  })
  const weight = `${n(packagesWeight, { maximumFractionDigits: 1 })} kg`
  return { carrier, packagesCount, weight, carrierId }
}
const fetchShipmentsTable = () => {
  shipments.value = []
  Object.keys(carriersStore.carriers)
    .filter(carriersStore.carrierSupportsPickupRequests)
    .forEach(async (carrier) => {
      await shipmentsStore.fetchShipments(
        { carrier, to_be_picked_up: '1' },
        { page: 1, per_page: 100 }
      )
      const row = shipmentTableRow(carrier)
      if (row.packagesCount > 0) shipments.value.push(row)
    })
}

const pickupRequestTableRow = (pickupRequest: PickupRequest): PickupRequestTableRow => {
  let pickupDate = ''
  try {
    if (pickupRequest.pickup_time) pickupDate = d(pickupRequest.pickup_time.earliest)
  } catch {
    /* empty */
  }
  return {
    id: pickupRequest.id,
    address: addressLines(pickupRequest.pickup_address).join(' '),
    carrier: t(`Shipment.carriers.${pickupRequest.carrier}`, pickupRequest.carrier),
    packagesCount: pickupRequest.shipments.length,
    pickupDate
  }
}
const fetchPickupRequestsTable = async () => {
  requests.value = []
  await pickupRequestsStore.fetchPickupRequests({}, paginationOptions.value)
  requestsPagination.value.itemCount = pickupRequestsStore.pickupRequestsTotal
  Object.values(pickupRequestsStore.pickupRequests).forEach(async (pickupRequest) => {
    const row = pickupRequestTableRow(pickupRequest)
    requests.value.push(row)
  })
}

const requestPaginationChanged = (pagination: SuitePaginationEventUpdate) => {
  paginationOptions.value.page = pagination.page
  paginationOptions.value.per_page = pagination.pageSize
  requestsPagination.value.page = pagination.page
  requestsPagination.value.pageSize = pagination.pageSize
}
const toPickupRequestDetails = (request: PickupRequestTableRow) => {
  router.push({ name: 'pickup-requests-details', params: { id: request.id } })
}

const onShipmentPickup = (e: any) =>
  router.push({ name: 'pickup-requests-new', params: { carrier: e.carrierId } })

watch(() => carriersStore.carriers, fetchShipmentsTable, { immediate: true, deep: true })
watch(paginationOptions, fetchPickupRequestsTable, { deep: true, immediate: true })

if (!carriersStore.hasItems) carriersStore.fetchCarriers()
</script>

<template>
  <SuitePageHeader
    :header="t('Office.PickupRequestsDashboard.pageHeader')"
    :subheader="t('Office.PickupRequestsDashboard.pageSubheader')"
  />
  <SuiteErrorMessage
    class="-mt-1 rounded-none"
    v-if="shipmentsStore.error || pickupRequestsStore.error"
  >
    {{ t('App.Error.fetchPickupRequestsError') }}
  </SuiteErrorMessage>
  <SuiteLoader :loading="pickupRequestsStore.loading">
    <SuitePickupRequests
      :requests
      :shipments
      :requestsPagination
      :shipmentsColumnHeadings
      :shipmentsHeading="t('Office.PickupRequestsDashboard.shipmentsHeading')"
      :requestsHeading="t('Office.PickupRequestsDashboard.requestsHeading')"
      :requestsButtonShowText="t('Office.PickupRequestsDashboard.requestsButtonShowText')"
      :shipmentsButtonPickupText="t('Office.PickupRequestsDashboard.shipmentsButtonPickupText')"
      @request-pagination="requestPaginationChanged"
      @shipment-pickup="onShipmentPickup"
      @request-open="(request) => toPickupRequestDetails(request as PickupRequestTableRow)"
    >
      <template #header>
        <BackLink name="suite-entry" />
      </template>
    </SuitePickupRequests>
  </SuiteLoader>
</template>
