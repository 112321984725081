import type { Shipment } from '@/api/shipcloud/shipment'
import { useCarriersStore } from '@/stores/carriers'
import { type SuiteItemDetailsProps } from '@shipcloud/suite-components'
import { computed, ref } from 'vue'
import type { useI18n } from 'vue-i18n'
import { buildCustomsDeclaration } from './buildCustomsDeclaration'
import { buildDetails } from './buildDetails'
import { buildDivider } from './buildDivider'
import { buildSummary } from './buildSummary'
import { buildTable } from './buildTable'

export const useShipmentDetails = (i18n: ReturnType<typeof useI18n>) => {
  const { t } = i18n()
  const carriersStore = useCarriersStore()
  const shipment = ref<Shipment>()
  const canBeCopied = computed<boolean>(() => shipment.value?.service !== 'returns')
  const canBeReturned = computed<boolean>(
    () => !!(shipment.value && carriersStore.carrierSupportsReturns(shipment.value.carrier))
  )

  const build = async (currentShipment: Shipment) => (shipment.value = currentShipment)

  const suiteItemDetailsProps = computed(() => {
    if (!shipment.value) return undefined

    return {
      summary: buildSummary(shipment.value, i18n),
      details: buildDetails(shipment.value, i18n),
      divider: buildDivider(t('Office.ShipmentDetails.packages')),
      table: buildTable(shipment.value, i18n),
      customsDeclaration: buildCustomsDeclaration(shipment.value, i18n)
    } as SuiteItemDetailsProps
  })

  return {
    build,
    suiteItemDetailsProps,
    canBeCopied,
    canBeReturned
  }
}
