import type { Delivery } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { deliveryToSuiteItemDetailsTableBody } from './body'
import { deliveryToSuiteItemDetailsTableHead } from './head'

export const deliveryToSuiteItemDetailsTable = (
  delivery: Delivery | undefined,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>,
  masterCarton = false
) => {
  if (!delivery?.deliveryItems?.length) return undefined

  return {
    body: deliveryToSuiteItemDetailsTableBody(delivery, i18n, router, masterCarton),
    head: deliveryToSuiteItemDetailsTableHead(delivery, i18n),
    noAlternatingRows: false
  } as SuiteItemDetailsProps['table']
}
