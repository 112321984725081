import type { Delivery, Shipment } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { deliveryToSuiteItemDetailsCustomsDeclaration } from './customsDeclaration'
import { deliveryToSuiteItemDetailsDetails } from './details'
import { deliveryToSuiteItemDetailsSummary } from './summary'
import { deliveryToSuiteItemDetailsTable } from './table'

interface DeliveryToSuiteItemDetailsParams {
  delivery: Delivery | undefined
  i18n: ReturnType<typeof useI18n>
  router: ReturnType<typeof createRouter>
  isMasterCarton?: boolean
  mainShipment?: Shipment | undefined
  masterCartonDeliveries?: Delivery[]
}

const deliveryToSuiteItemDetailsDefaults: Partial<DeliveryToSuiteItemDetailsParams> = {
  isMasterCarton: false,
  mainShipment: undefined,
  masterCartonDeliveries: []
}

export const deliveryToSuiteItemDetails = (params: DeliveryToSuiteItemDetailsParams) => {
  const { delivery, i18n, mainShipment, isMasterCarton, masterCartonDeliveries, router } =
    Object.assign(
      {},
      deliveryToSuiteItemDetailsDefaults,
      params
    ) as Required<DeliveryToSuiteItemDetailsParams>

  if (!delivery) return undefined

  const { t } = i18n

  const text = isMasterCarton ? t('ParcelCore.shipmentItem') : t('Delivery.deliveryItems')

  return {
    customsDeclaration: deliveryToSuiteItemDetailsCustomsDeclaration(
      masterCartonDeliveries,
      i18n,
      router
    ),
    details: deliveryToSuiteItemDetailsDetails(delivery, i18n, isMasterCarton),
    divider: { icon: text[0].toUpperCase(), text },
    summary: deliveryToSuiteItemDetailsSummary({
      delivery,
      i18n,
      mainShipment
    }),
    table: deliveryToSuiteItemDetailsTable(delivery, i18n, router, isMasterCarton)
  } as SuiteItemDetailsProps
}
