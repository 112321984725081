<script setup lang="ts">
import router from '@/router'
import { SuiteIcon } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'
import type { RouteLocationRaw } from 'vue-router'

export type BackLinkProps = { name: string; params?: any }
const props = defineProps<BackLinkProps>()
const { t } = useI18n()

const redirect = () => {
  if (window.history.state.back) window.history.back()
  else router.push(props as RouteLocationRaw)
}
</script>

<template>
  <a @click="redirect" class="inline-flex cursor-pointer items-center" data-identifier="link-back">
    <SuiteIcon icon="ChevronLeft" class="mr-2 size-4" />
    {{ t('Office.ShipmentDetails.back') }}
  </a>
</template>
