import { z } from 'zod'

export const ADDRESS_ROLES = ['delivery', 'pickup'] as const
export type AddressRole = (typeof ADDRESS_ROLES)[number]
export const Address = z.object({
  company: z.string().optional().default(''),
  firstName: z.string().default(''),
  lastName: z.string().optional().default(''),
  addressLine2: z.string().optional().default(''),
  street: z.string().default(''),
  streetno: z.string().optional().default(''),
  zipcode: z.string().default(''),
  city: z.string().default(''),
  state: z.string().optional().default(''),
  country: z.string().default(''),
  email: z.string().optional().default(''),
  phone: z.string().optional().default(''),
  role: z.enum(ADDRESS_ROLES)
})
export type Address = z.infer<typeof Address>

export const uneditableAddressProperties = ['role'] as const
