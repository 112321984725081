import type { Delivery, Shipment } from '@/api/parcelCore'
import type { SuiteItemDetailsProps } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

const deliveryItems = (delivery: Delivery, i18n: ReturnType<typeof useI18n>) => {
  const { d, t } = i18n
  return [
    {
      label: t('Delivery.deliveryNumber'),
      value: delivery.deliveryNumber
    },
    {
      label: t('Shipment.created_at'),
      value: d(delivery.createdAt)
    }
  ]
}

const shipmentItems = (shipment: Shipment | undefined, i18n: ReturnType<typeof useI18n>) => {
  if (!shipment) return []
  const { t } = i18n
  return [
    {
      label: t('Shipment.carrier'),
      value: shipment.carrierName
    },
    {
      label: t('Shipment.service'),
      value: shipment.productName
    }
  ]
}

interface DeliveryToSuiteItemDetailsSummaryParams {
  delivery: Delivery | undefined
  i18n: ReturnType<typeof useI18n>
  mainShipment: Shipment | undefined
}

const deliveryToSuiteItemDetailsSummaryDefaults: Partial<DeliveryToSuiteItemDetailsSummaryParams> =
  {
    mainShipment: undefined
  }

export const deliveryToSuiteItemDetailsSummary = (
  params: DeliveryToSuiteItemDetailsSummaryParams
): SuiteItemDetailsProps['summary'] => {
  const { delivery, i18n, mainShipment } = Object.assign(
    {},
    deliveryToSuiteItemDetailsSummaryDefaults,
    params
  ) as Required<DeliveryToSuiteItemDetailsSummaryParams>

  if (!delivery) return undefined

  return {
    items: [...deliveryItems(delivery, i18n), ...shipmentItems(mainShipment, i18n)]
  }
}
