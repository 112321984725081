import { Platform, TokenPayload } from '@/api/authentication/token'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAuthenticationV2Store = defineStore(
  'authenticationV2',
  () => {
    const token = ref<string>('')
    const challenge = ref<string>('')
    const payload = ref<TokenPayload>()

    const valid = (): boolean => token.value.length > 0 && !!payload.value && !expired()

    const expired = (): boolean => {
      const now = new Date()
      const expiresAt = new Date(payload.value!.exp * 1000) // exp = unix epoch in seconds
      return !(expiresAt.getTime() > now.getTime())
    }

    const clear = () => {
      token.value = ''
      challenge.value = ''
      payload.value = undefined
    }

    const platform = (name: string): Platform | undefined => {
      if (!payload.value?.platforms) return

      return Array.from(payload.value.platforms).find((platform) => platform.platform_name == name)
    }

    return { token, challenge, payload, clear, platform, valid }
  },
  { persist: true }
)
