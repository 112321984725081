import type { Shipment } from '@/api/shipcloud/shipment'
import { useI18n } from 'vue-i18n'
import { buildCustomsDetails } from './buildCustomsDetails'
import { buildCustomsSummary } from './buildCustomsSummary'
import { buildCustomsTable } from './buildCustomsTable'
import { buildDivider } from './buildDivider'

export const buildCustomsDeclaration = (shipment: Shipment, i18n: ReturnType<typeof useI18n>) => {
  if (!shipment.customs_declaration) return undefined

  const { t } = useI18n()

  return {
    summary: buildCustomsSummary(shipment.customs_declaration, i18n),
    details: buildCustomsDetails(shipment.customs_declaration, i18n),
    divider: buildDivider(t('Shipment.customs_declaration')),
    table: buildCustomsTable(shipment.customs_declaration, i18n)
  }
}
