import { useCarriersStore } from '@/stores/parcelCore'
import { computed, ref } from 'vue'

export const useProductCode = (carrierStore: ReturnType<typeof useCarriersStore>) => {
  const selectedCarrierCode = ref()

  const carrierOptions = computed(() =>
    carrierStore.carriers.map((carrier) => ({
      label: carrier.carrierName,
      value: carrier.carrierCode
    }))
  )

  const selectedCarrier = computed(() =>
    carrierStore.carriers.find((carrier) => carrier.carrierCode == selectedCarrierCode.value)
  )

  const serviceOptions = computed(() => {
    if (!selectedCarrier.value) return []

    return selectedCarrier.value.products.map((product) => ({
      label: product.productName,
      value: product.productCode
    }))
  })

  return { selectedCarrierCode, carrierOptions, serviceOptions }
}
