<script setup lang="ts">
import { FormDelivery, OUTPUT_FORMATS } from '@/api/apiLayer'
import { useCarriersStore } from '@/stores/parcelCore'
import { SuiteButton, SuiteInputLabel, SuiteLoader, SuiteSelect } from '@shipcloud/suite-components'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useProductCode } from './useProductCode'

const { t } = useI18n()
const saving = ref(false)
const outputFormat = ref('pdf_a6')
const token = ref('')
const delivery = defineModel<FormDelivery>()
const carrierStore = useCarriersStore()
const outputFormatOptions = computed(() =>
  OUTPUT_FORMATS.map((fmt) => ({ label: t(`OutputFormats.${fmt}`, fmt), value: fmt }))
)
const { carrierOptions, serviceOptions, selectedCarrierCode } = useProductCode(carrierStore)
const emit = defineEmits<{
  (e: 'save', token: string, outputFormat: string): void
}>()
if (!carrierStore.carriers.length) carrierStore.fetchAll({}, { pageNumber: 1, pageSize: 50 })
</script>
<template>
  <div class="border-sky-10 space-y-4 rounded-lg border bg-white p-5 px-5">
    <div class="text-xl leading-tight font-bold">
      {{ t('DeliveryForm.formActionsHeader') }}
    </div>
    <div class="grid gap-3" v-if="delivery?.deliveryType">
      <SuiteInputLabel label-position="top">
        <template #label>{{ t('DeliveryForm.carrier') }}</template>
        <SuiteSelect
          id="select-carrier"
          class="w-full"
          :options="carrierOptions"
          :placeholder="t('DeliveryForm.carrier')"
          v-model="selectedCarrierCode"
        />
      </SuiteInputLabel>
      <SuiteInputLabel label-position="top">
        <template #label>{{ t('DeliveryForm.service') }}</template>
        <SuiteSelect
          id="select-service"
          class="w-full"
          :options="serviceOptions"
          :placeholder="t('DeliveryForm.servicePlaceholder')"
          v-model="delivery.deliveryType.productCode"
        />
      </SuiteInputLabel>
      <SuiteInputLabel label-position="top">
        <template #label>Output format</template>
        <SuiteSelect
          id="select-output-format"
          :placeholder="t('DeliveryForm.outputFormat')"
          :options="outputFormatOptions"
          v-model="outputFormat"
          class="w-full"
        />
      </SuiteInputLabel>
    </div>
    <div class="mt-5 flex gap-3">
      <SuiteLoader :loading="saving">
        <SuiteButton
          id="submit-button"
          class="inline-block min-w-40 px-5"
          @click="emit('save', token, outputFormat)"
          :disabled="saving"
        >
          {{ t('DeliveryForm.submit') }}
        </SuiteButton>
      </SuiteLoader>
    </div>
  </div>
</template>
