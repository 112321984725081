import type { Deliveries, DeliveryType } from '@/api/parcelCore'
import type { SuiteTableNestedProps } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'
import type { createRouter } from 'vue-router'
import { deliveriesToSuiteTableNestedBody } from './body'
import { deliveriesToSuiteTableNestedHead } from './head'

export const deliveriesToSuiteTableNested = (
  deliveries: Deliveries,
  i18n: ReturnType<typeof useI18n>,
  router: ReturnType<typeof createRouter>,
  deliveryType?: DeliveryType
) => {
  if (!deliveries.length) return undefined

  deliveryType ??= 'deliveries'

  return {
    body: deliveriesToSuiteTableNestedBody(deliveries, deliveryType || 'deliveries', i18n, router),
    cols: { 7: { class: 'w-4' } },
    head: deliveriesToSuiteTableNestedHead(i18n),
    headStyle: 'blue'
  } as SuiteTableNestedProps
}
