import type { Carrier } from '@/api/shipcloud/carrier'
import { ShipmentFilter, TrackingStatus } from '@/api/shipcloud/shipment'
import type { SuiteFilterProps } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

const getCarrierFilterOptions = (i18n: ReturnType<typeof useI18n>, carriers: Carrier[]) => {
  const { t } = i18n
  const options: { label: string; value: any }[] = []

  carriers.forEach((carrier) => {
    options.push({ label: carrier.display_name, value: carrier.name })
  })

  options.sort((a, b) => a.label.localeCompare(b.label))
  options.unshift({ label: t('Office.ShipmentsDashboard.showAll'), value: '' })

  return options
}

const getServiceFilterOptions = (i18n: ReturnType<typeof useI18n>, services: Set<string>) => {
  const { t } = i18n
  const options: { label: string; value: any }[] = []

  services.forEach((serviceName) => {
    options.push({ label: t(`Shipment.services.${serviceName}`), value: serviceName })
  })

  options.sort((a, b) => a.label.localeCompare(b.label))
  options.unshift({ label: t('Office.ShipmentsDashboard.showAll'), value: '' })

  return options
}

const getTrackingStatusFilterOptions = (i18n: ReturnType<typeof useI18n>) => {
  const { t } = i18n
  const options: { label: string; value: any }[] = []

  const trackingStates = Object.keys(TrackingStatus.Values)
  trackingStates.forEach((state) => {
    options.push({ label: t(`TrackingEvent.status.${state}`), value: state })
  })

  options.sort((a, b) => a.label.localeCompare(b.label))
  options.unshift({ label: t('Office.ShipmentsDashboard.showAll'), value: '' })
  return options
}

export const getShipmentsFilterProps = (
  i18n: ReturnType<typeof useI18n>,
  carriers: Carrier[],
  services: Set<string>,
  filter: ShipmentFilter
): SuiteFilterProps => {
  const { t } = i18n

  return {
    filters: {
      carrier_tracking_no: {
        label: t('Package.carrier_tracking_no'),
        placeholder: t('Package.carrier_tracking_no'),
        type: 'text'
      },
      reference_number: {
        label: t('Shipment.reference_number'),
        placeholder: t('Shipment.reference_number'),
        type: 'text'
      },
      tracking_status: {
        label: t('Package.status'),
        type: 'select',
        options: getTrackingStatusFilterOptions(i18n)
      },
      carrier: {
        label: t('Shipment.carrier'),
        type: 'select',
        options: getCarrierFilterOptions(i18n, carriers)
      },
      service: {
        label: t('Shipment.service'),
        type: 'select',
        options: getServiceFilterOptions(i18n, services)
      },
      created_at_gt: {
        label: t('Shipment.created_at'),
        type: 'date'
      }
    },
    modelValue: filter,
    strAddFilterSelectionPlaceholder: t('Office.ShipmentsDashboard.filterSelectionPlaceholder'),
    strButtonAddFilter: t('Office.ShipmentsDashboard.filterAddButton'),
    strButtonAddFilterCancelAdd: t('Office.ShipmentsDashboard.filterCancelAddButton'),
    strButtonAddFilterCancelEdit: t('Office.ShipmentsDashboard.filterCancelEditButton'),
    strFilterToAddLabelFilter: t('Office.ShipmentsDashboard.filterLabel'),
    strFilterToAddLabelValue: t('Office.ShipmentsDashboard.filterValueLabel'),
    strNoFiltersSet: t('Office.ShipmentsDashboard.filterEmptyLabel'),
    strSetFilterButton: t('Office.ShipmentsDashboard.filterSetButton')
  }
}
