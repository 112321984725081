import { z } from 'zod'
import { apiCall, ApiError } from '.'

export const DOCUMENT_ENTITY_TYPES = ['Delivery', 'DeliveryItem'] as const
export const DOCUMENT_TYPES = [
  'ADR_SLIP',
  'CERTIFICATE_OF_ORIGIN',
  'CN22',
  'CN23',
  'COD_SLIP',
  'COMMERCIAL_INVOICE',
  'CONTENT',
  'CP71',
  'HEADERS',
  'IATA_SLIP',
  'LABEL',
  'OTHER',
  'PROFORMA_INVOICE',
  'RETURN_QR_CODE',
  'RETURN_SLIP',
  'URI'
] as const
export const DOCUMENT_FORMATS = ['PDF'] as const
export const DeliveryDocument = z.object({
  content: z.string(),
  format: z.string(),
  type: z.enum(DOCUMENT_TYPES)
})
export const DocumentUpload = z.object({
  documents: DeliveryDocument.array(),
  entityId: z.string(),
  entityType: z.enum(DOCUMENT_ENTITY_TYPES)
})
export const DocumentUploadResponse = z.object({ requestId: z.string() })

export type DeliveryDocument = z.infer<typeof DeliveryDocument>
export type DocumentUpload = z.infer<typeof DocumentUpload>
export type DocumentUploadResponse = z.infer<typeof DocumentUploadResponse>

export const createDocument = (token: string, request: DocumentUpload) =>
  apiCall({
    data: request,
    method: 'POST',
    path: 'documents',
    token
  }) as unknown as DocumentUploadResponse | ApiError
