import type { CustomsDeclaration } from '@/api/shipcloud/shipment'
import { type SuiteTableNestedRow } from '@shipcloud/suite-components'
import type { useI18n } from 'vue-i18n'

export const buildCustomsSummary = (
  customsDeclaration: CustomsDeclaration,
  i18n: ReturnType<typeof useI18n>
) => {
  const { d, t } = i18n()

  const items: SuiteTableNestedRow['cells'] = [
    {
      label: t('CustomsDeclaration.contents_type'),
      value: t(
        `CustomsDeclaration.contents_types.${customsDeclaration.contents_type}`,
        customsDeclaration.contents_type
      )
    },
    {
      label: t('CustomsDeclaration.contents_explanation'),
      value: customsDeclaration.contents_explanation
    },
    {
      label: t('CustomsDeclaration.posting_date'),
      value: d(customsDeclaration.posting_date || '')
    },
    {
      label: t('CustomsDeclaration.document_type'),
      value: t(
        `CustomsDeclaration.document_types.${customsDeclaration.document_type}`,
        customsDeclaration.document_type || ''
      )
    }
  ]

  return { items }
}
