import { z } from 'zod'

export const RestParams = z.object({
  data: z.any().nullable(),
  method: z.enum(['POST']),
  path: z.string(),
  token: z.string()
})
export type RestParams = z.infer<typeof RestParams>

export const ApiErrorMessage = z.object({
  category: z.string(),
  message: z.string()
})
export const ApiError = ApiErrorMessage.array()
export type ApiErrorMessage = z.infer<typeof ApiErrorMessage>
export type ApiError = z.infer<typeof ApiError>
