import type { Package, Shipment, TrackingStatus } from '@/api/shipcloud/shipment'
import { TrackingLink } from '@/components'
import { TrackingDetails } from '@/components/ShipmentDetails'
import { packageStatus, trackingStatusColors, weight, windowOpen } from '@/helpers'
import type { SuiteTableNestedRow } from '@shipcloud/suite-components'
import { StatusCaption, SuiteButton } from '@shipcloud/suite-components'
import { markRaw } from 'vue'
import { useI18n } from 'vue-i18n'

const packageTableRow = (
  pkg: Package,
  shipment: Shipment,
  i18n: ReturnType<typeof useI18n>
): SuiteTableNestedRow['cells'] => {
  const { n, t } = i18n()
  const status = packageStatus(pkg)
  const row: SuiteTableNestedRow['cells'] = [
    {
      component: markRaw(TrackingLink),
      content: pkg.carrier_tracking_no || shipment.carrier_tracking_no,
      props: { url: pkg.carrier_tracking_url || shipment.carrier_tracking_url }
    },
    pkg.description,
    [pkg.height, pkg.width, pkg.length].sort().reverse().map(n).join(' cm x ').concat(' cm'),
    weight(n(pkg.weight)),
    t(`Package.package_types.${pkg.type}`, pkg.type),
    {
      component: markRaw(StatusCaption),
      content: t(`TrackingEvent.status.${status}`),
      props: { color: trackingStatusColors(status as TrackingStatus) }
    }
  ]
  if (pkg.label_url) {
    row.push({
      component: markRaw(SuiteButton),
      props: {
        class: 'pl-3',
        icon: 'FileText',
        'data-identifier': 'pdf-label',
        onClick: () => windowOpen(pkg.label_url as string)
      }
    })
  }
  if (pkg.label_voucher_url) {
    row.push({
      component: markRaw(SuiteButton),
      props: {
        class: 'pl-3',
        icon: 'QrcodeCurrentcolor',
        'data-identifier': 'qr-label',
        onClick: () => windowOpen(pkg.label_voucher_url as string)
      }
    })
  }
  return row
}
const packageChildren = (pkg: Package, shipment: Shipment): SuiteTableNestedRow['children'] => {
  const cells = [
    {
      colspan: 99,
      component: markRaw(TrackingDetails),
      props: { pkg, shipment }
    }
  ]
  return [{ cells }]
}

export const buildTable = (shipment: Shipment, i18n: ReturnType<typeof useI18n>) => {
  const { t } = i18n()
  const head = [
    {
      cells: [
        t('Package.carrier_tracking_no'),
        t('Package.description'),
        t('Package.dimensions'),
        t('Package.weight'),
        t('Package.package_type'),
        t('Package.status'),
        ''
      ]
    }
  ]
  const body = shipment.packages.map((pkg) => {
    return { cells: packageTableRow(pkg, shipment, i18n), children: packageChildren(pkg, shipment) }
  })
  return { head, body, noAlternatingRows: false }
}
