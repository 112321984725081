<script setup lang="ts">
import {
  SuiteContentPanel,
  SuiteErrorMessage,
  SuiteFilter,
  SuiteLoader,
  SuitePageHeader,
  SuitePagination,
  SuiteTableNested,
  type SuitePaginationEventUpdate,
  type SuitePaginationProps
} from '@shipcloud/suite-components'
import { computed, reactive, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import type { PaginationOptions } from '@/api/shipcloud'
import { ShipmentFilter } from '@/api/shipcloud/shipment'
import { useCarriersStore } from '@/stores/carriers'
import { useShipmentFilterStore } from '@/stores/shipmentFilter'
import { useShipmentsStore } from '@/stores/shipments'
import { getShipmentsFilterProps, shipmentsToSuiteTableNested } from './helpers'

const i18n = useI18n()
const { t } = i18n
const router = useRouter()
const route = useRoute()
const carriersStore = useCarriersStore()
const shipmentsStore = useShipmentsStore()
const shipmentFilterStore = useShipmentFilterStore()
const pageSizeOptions = [25, 50, 100]
const paginationState = reactive<PaginationOptions>({ page: 1, per_page: pageSizeOptions[0] })

if (!carriersStore.hasItems) carriersStore.fetchCarriers()

watch(
  () => route,
  () => {
    if (Object.keys(ShipmentFilter.parse(route.query)).length != 0) {
      shipmentFilterStore.filter = ShipmentFilter.parse(route.query)
    }
  },
  { immediate: true }
)

watchEffect(() => {
  router.push({ query: { ...shipmentFilterStore.filter } })
})

const shipmentsTableProps = computed(() =>
  shipmentsToSuiteTableNested(Object.values(shipmentsStore.shipments), i18n, router)
)

const shipmentsPaginationProps = computed<SuitePaginationProps>(() => ({
  page: paginationState.page,
  pageSize: paginationState.per_page,
  pageSizeOptions,
  itemCount: shipmentsStore.shipmentsTotal
}))

const shipmentsPaginationHandler = (e: SuitePaginationEventUpdate) => {
  Object.assign(paginationState, {
    page: e.page,
    per_page: e.pageSize
  })
}

watch(
  [paginationState, shipmentFilterStore.filter],
  () => shipmentsStore.fetchShipments(shipmentFilterStore.filter, paginationState),
  { immediate: true }
)

const shipmentsFilterProps = computed(() =>
  getShipmentsFilterProps(
    i18n,
    Object.values(carriersStore.carriers),
    carriersStore.services,
    shipmentFilterStore.filter
  )
)
</script>

<template>
  <SuiteLoader page :loading="shipmentsStore.loading" />
  <SuiteErrorMessage class="-mt-1 rounded-none" v-if="shipmentsStore.error">
    {{ t('App.Error.fetchShipmentsError') }}
  </SuiteErrorMessage>
  <template v-else-if="!shipmentsStore.loading">
    <SuitePageHeader
      :header="t('Office.ShipmentsDashboard.pageHeader')"
      :subheader="t('Office.ShipmentsDashboard.pageSubheader')"
    >
      <SuiteFilter v-bind="shipmentsFilterProps" data-identifier="shipments-filter" class="mt-2" />
    </SuitePageHeader>
    <SuiteContentPanel>
      <div v-if="shipmentsTableProps" class="space-y-4">
        <SuiteTableNested
          v-bind="shipmentsTableProps"
          data-identifier="shipments-table"
          class="w-full bg-white"
        >
          <template #body_0="{ cell, row }">
            <button
              v-if="cell.isMultiColli"
              class="text-sky font-semibold"
              data-identifier="multicolli-toggle"
              @click="row.toggleOpenParent()"
            >
              {{
                t('Office.ShipmentsDashboard.packageAmount', {
                  number: cell.packagesCount
                })
              }}
              ({{
                row.isOpenParent
                  ? t('Office.ShipmentsDashboard.hide')
                  : t('Office.ShipmentsDashboard.show')
              }})
            </button>
          </template>
        </SuiteTableNested>
        <SuitePagination
          v-bind="shipmentsPaginationProps"
          data-identifier="shipments-pagination"
          @update="shipmentsPaginationHandler"
        />
      </div>
      <div class="-mt-1 rounded-none p-4 text-center" v-else>
        {{ t('Office.ShipmentsDashboard.noShipmentsFound') }}
      </div>
    </SuiteContentPanel>
  </template>
</template>
